import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {saveAs} from "file-saver";
import * as htmlToImage from "html-to-image";
import {ToastrService} from "ngx-toastr";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import {firstValueFrom} from "rxjs";
import * as XLSX from "../../lib/xlsx.full.min.js";
import {ConfirmPopupComponent} from "../common/confirm-popup/confirm-popup.component";
import countriesData from "../common/countries.json";
import {ImageUtil} from "../common/ImageUtil";
import {UserDTO} from "../common/user.service.js";
import {ProductDetailsService} from "../product-details/product-details.service";
import {ActionPlanExportable} from "./action-plan-exportable";
import {ActionPlan, ActionPlanService} from "./action-plan.service";
import {ManageActionComponent} from "./manage-action/manage-action.component";
import {MaterialIconsBase64} from "./material-icons-base-64";

@Component({
  selector: "app-action-plan",
  templateUrl: "./action-plan.component.html",
  styleUrls: ["./action-plan.component.scss"],
})
export class ActionPlanComponent implements OnInit, OnChanges {
  @Input() productId: number;
  @Input() isWriter: boolean;
  @Input() isAdmin: boolean = false;
  @Input() productName: string;
  @Input() productOwners: Array<string>;

  @Output() sendLastUpdate: EventEmitter<Date> = new EventEmitter<Date>();

  countries: Array<{ code: string; name: string; flag: string }> = [];
  actionPlans: ActionPlan[] = [];
  filteredActionPlans: ActionPlan[] = [];

  statusFilter: string;
  typeFilter: string;
  lbuFilter: string;
  pillarFilter: string;
  responsibleFilter: string;
  deadlineFilter: Date = null;

  statusOptions: string[] = [];
  typeOptions: string[] = [];
  lbuOptions: { code: string; name: string; flag: string }[];
  pillarOptions: string[] = [];
  responsibleOptions: string[] = [];
  currentAction;

  constructor(
    private actionPlanService: ActionPlanService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private productDetailsService: ProductDetailsService
  ) {
    this.countries = Object.entries(countriesData).map(([code, name]) => ({
      code,
      name,
      flag: `assets/flags/${code.toLowerCase()}.svg`,
    }));
  }

  ngOnInit() {
    this.loadActionPlansByProductId(this.productId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.productId?.currentValue) {
      this.loadActionPlansByProductId(this.productId);
    }
  }

  loadActionPlansByProductId(productId: number): void {
    this.actionPlanService.getActionPlansByProductId(productId).subscribe((data) => {
      this.sendLastUpdate.emit(data.actionPlanLastUpdateDate);
      this.actionPlans = data.actionPlans;
      this.extractUniqueOptions();
      this.filteredActionPlans = [...this.actionPlans];
    });
  }

  extractUniqueOptions(): void {
    const statuses = new Set<string>();
    const types = new Set<string>();
    const lbus = new Set<{ code: string; name: string; flag: string }>();
    const pillars = new Set<string>();
    const responsibles = new Set<string>();

    this.actionPlans.forEach((plan) => {
      statuses.add(plan.status);
      types.add(plan.type);

      const lbusFromComment = this.extractLBUs(plan.comment);
      lbusFromComment.forEach((lbu) => {
        const country = this.countries.find((c) => c.name.toLowerCase() === lbu.toLowerCase());
        if (country) {
          lbus.add(country);
        }
      });

      pillars.add(plan.pillarName);
      responsibles.add(plan.responsible.fullName);
    });

    this.statusOptions = Array.from(statuses);
    this.typeOptions = Array.from(types);
    this.lbuOptions = Array.from(lbus);
    this.pillarOptions = Array.from(pillars);
    this.responsibleOptions = Array.from(responsibles);
  }

  extractLBUs(comment: string): string[] {
    const parser = new DOMParser();
    const doc = parser.parseFromString(comment, "text/html");
    const mentions = doc.querySelectorAll("[data-mention-name]");
    return Array.from(mentions).map((el) => el.getAttribute("data-mention-name"));
  }

  formatComment(comment: string): string[] {
    return comment
      .trim()
      .replace(/@(\w+)/g, "@$1 ")
      .split(" ");
  }

  filterActionPlans(): void {
    const deadlineTimestamp = this.deadlineFilter ? new Date(this.deadlineFilter) : null;
    this.filteredActionPlans = this.actionPlans.filter((plan) => {
      const isLbuFilterValid = this.lbuFilter == null || (this.lbuFilter === "None"
        ? !plan.comment.includes("@")
        : plan.comment.toLowerCase().includes(`@${this.lbuFilter.toLowerCase()}`));

      return (
        (this.statusFilter ? plan.status.toLowerCase().includes(this.statusFilter.toLowerCase()) : true) &&
        (this.typeFilter ? plan.type.toLowerCase().includes(this.typeFilter.toLowerCase()) : true) &&
        isLbuFilterValid &&
        (this.pillarFilter ? plan.pillarName.toLowerCase().includes(this.pillarFilter.toLowerCase()) : true) &&
        (this.responsibleFilter ? plan.responsible.fullName.toLowerCase().includes(this.responsibleFilter.toLowerCase()) : true) &&
        (this.deadlineFilter != null ? new Date(plan.deadline) <= deadlineTimestamp : true)
      );
    });
  }

  resetFilters(): void {
    this.statusFilter = null;
    this.typeFilter = null;
    this.lbuFilter = null;
    this.pillarFilter = null;
    this.responsibleFilter = null;
    this.deadlineFilter = null;
    this.filteredActionPlans = [...this.actionPlans];
  }

  isCountry(countryName: string): boolean {
    return this.countries.some((country) => country.name.toLowerCase() === countryName.toLowerCase());
  }

  getStatusColor(status: string): string {
    switch (status) {
      case "To do":
        return "#285aff"; // Blue
      case "In progress":
        return "#fb9a28"; // Orange
      case "Blocked":
        return "#ed0000"; // Red
      case "Done":
        return "#40a900"; // Green
      case "Non Applicable":
        return "#85888a"; // Grey
      default:
        return "#000000";
    }
  }

  getStatusIcon(status: string): string {
    switch (status) {
      case "To do":
        return "change_circle";
      case "In progress":
        return "alarm_on";
      case "Blocked":
        return "cancel";
      case "Done":
        return "check_circle";
      case "Non Applicable":
        return "reorder";
      default:
        return "help_outline";
    }
  }

  formatDate(date: Date): string {
    const parsedDate = new Date(date);
    return date != null ? parsedDate.toLocaleDateString("en-GB") : "";
  }

  /**MANAGE ACTIONS**/
  setCurrentAction(action: any): void {
    this.currentAction = action;
  }

  editAction(actionId: number): void {
    const actionToEdit = this.filteredActionPlans.find((action) => action.id === actionId);

    this.dialog
      .open(ManageActionComponent, {
        width: "min-content",
        data: {
          productId: this.productId,
          action: actionToEdit,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.refreshActionPlans();
        }
      });
  }

  deleteAction(actionId: number): void {
    this.dialog
      .open(ConfirmPopupComponent, {
        width: "30vw",
        data: {
          icon: "delete",
          title: "Delete Action",
          text: "Are you sure you want to delete this action?",
        },
        position: {
          top: "20px",
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res === "confirm") {
          this.actionPlanService.deleteAction(actionId).subscribe(
            () => {
              this.toastrService.success("Action has been deleted!");
              this.refreshActionPlans();
            },
            () => {
              this.toastrService.error("An error occurred while deleting the action.");
            }
          );
        }
      });
  }

  refreshActionPlans() {
    this.loadActionPlansByProductId(this.productId);
  }

  changeActionPlanStatus(actionPlan: ActionPlan, status: string) {
    if (actionPlan.status != status) {
      this.actionPlanService.changeActionPlanStatus(actionPlan.id, status).subscribe(
        () => {
          this.toastrService.success("Status has been updated !");
          this.refreshActionPlans();
        },
        () => {
          this.toastrService.error("An error occurred while updating the status.");
        }
      );
    }
  }

  exportInXlsx() {
    const workbook = XLSX.utils.book_new();

    let dataToExport = this.filteredActionPlans.map((actionPlan: ActionPlan) => this.actionPlanToExportable(actionPlan));

    let colWidth = this.getWidthOfEachColumn(dataToExport);

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    worksheet["!cols"] = colWidth;

    XLSX.utils.book_append_sheet(workbook, worksheet, "Action Plan");
    XLSX.writeFile(workbook, "Action plan.xlsx", {compression: true});
  }

  actionPlanToExportable(actionPlan: ActionPlan): ActionPlanExportable {
    let actionPlanXlsx = new ActionPlanExportable();
    actionPlanXlsx.Status = actionPlan.status;
    actionPlanXlsx.Type = actionPlan.type;
    actionPlanXlsx["Action / Decision / Info"] = actionPlan.title;
    actionPlanXlsx["Creation Date"] =
      actionPlan.committee != "No committee"
        ? actionPlan.committee + " : " + this.formatDate(actionPlan.creationDate)
        : this.formatDate(actionPlan.creationDate);
    actionPlanXlsx.Comment = actionPlan.comment;
    actionPlanXlsx.Pillars = actionPlan.pillarName;
    actionPlanXlsx.Responsible = actionPlan.responsible.fullName;
    actionPlanXlsx.Deadline = this.formatDate(actionPlan.deadline);

    return actionPlanXlsx;
  }

  getWidthOfEachColumn(dataToExport) {
    let objectMaxLength = [];
    const WIDTH_MARGIN = 3;

    let allToConsider = [...dataToExport];
    allToConsider.push(Object.keys(dataToExport[0]));

    for (const element of allToConsider) {
      let value = <any>Object.values(element);

      for (let j = 0; j < value.length; j++) {
        if (typeof value[j] == "number") {
          objectMaxLength[j] = 10;
        } else {
          let lengthOfObject = value[j].length + WIDTH_MARGIN;

          if (!objectMaxLength[j] || lengthOfObject > objectMaxLength[j]) {
            objectMaxLength[j] = lengthOfObject;
          }
        }
      }
    }

    const colWidth = [];
    for (let j = 0; j < <any>Object.values(dataToExport[0]).length; j++) {
      colWidth.push({width: objectMaxLength[j]});
    }

    return colWidth;
  }

  exportInJpeg() {
    htmlToImage
      .toJpeg(document.getElementById("action-plan-table"), {
        quality: 1,
        backgroundColor: "white",
        filter: (domNode) => domNode.className == null || domNode.className.indexOf("no-export") == -1,
      })
      .then(function (dataUrl) {
        saveAs(dataUrl, "Action plan.jpeg");
      })
      .catch(() => {
        this.toastrService.error("Failed to export the action plan as a JPEG.");
      });
  }

  async exportInPdf() {
    (<any>pdfMake).addVirtualFileSystem(pdfFonts);

    let dataToExport = this.filteredActionPlans.map((actionPlan: ActionPlan) => this.actionPlanToExportable(actionPlan));

    let tableBody: Array<Array<any>> = [
      Object.keys(dataToExport[0]).map((header) => {
        return {text: header, style: "tableText"};
      }),
    ];

    dataToExport.forEach((dataRow) =>
      tableBody.push(
        Object.values(dataRow).map((value) => {
          let base64Img;
          if (value == "To do") base64Img = MaterialIconsBase64.TO_DO;
          else if (value == "In progress") base64Img = MaterialIconsBase64.IN_PROGRESS;
          else if (value == "Blocked") base64Img = MaterialIconsBase64.BLOCKED;
          else if (value == "Done") base64Img = MaterialIconsBase64.DONE;
          else if (value == "Non Applicable") base64Img = MaterialIconsBase64.NON_APPLICABLE;

          if (base64Img != null) {
            return {
              image: base64Img,
              alignment: "center",
              fit: [10, 10],
              margin: [0, value != "Non Applicable" ? 2 : 2.5, 0, 0],
            };
          } else {
            return {text: value, style: "tableText", margin: [0, 3, 0, 3]};
          }
        })
      )
    );

    let productDetails: any = await firstValueFrom(this.productDetailsService.getProductDetails(this.productId));
    let productOwners: Array<string> = productDetails.productOwners.map((productOwner: UserDTO) => productOwner.fullName);

    let productOwnersText = "Product Owner";
    if (productOwners.length > 1) productOwnersText += "s";
    productOwnersText += " : " + productOwners.join(", ");

    let tableWidth: Array<any> = ["9.5%", "8.5%", "18%", "13%", "16%", "13.5%", "12.5%", "9%"];

    let logo = productDetails.logo;

    if (logo == null) logo = await ImageUtil.getBase64ImageFromUrl("assets/logo_deploy_cockpit.svg");

    let docDefinition = {
      content: [
        {
          columns: [
            {
              width: 100,
              image: logo,
              fit: [100, 100],
            },
            {
              width: "*",
              text: this.productName,
              margin: [0, 25, 0, 25],
              fontSize: 15,
              bold: true,
            },
          ],
        },
        {
          text: productOwnersText,
          fontSize: 8,
          italics: true,
          margin: [0, 0, 0, 15],
          color: "#8C8C8C",
        },
        {
          text: "Action plan",
          style: "actionPlanHeader",
          margin: [0, 0, 0, 10],
          fontSize: 13,
        },
        {
          table: {
            widths: tableWidth,
            body: tableBody,
          },
          layout: "lightHorizontalLines",
        },
      ],
      styles: {
        tableText: {
          fontSize: 7,
        },
      },
    };

    pdfMake.createPdf(docDefinition).download("Action plan.pdf");
  }
}
