<popup-header [dialogRef]="dialogRef" icon="calendar_today" text="{{ title }}"></popup-header>
<div class="add-group-dialog">
  <form [formGroup]="addGroupForm">
    <!-- Title of the group -->
    <div class="form-group">
      <label for="groupTitle" class="form-label">Group Title <span style="color: red">*</span></label>
      <input type="text" class="form-control" id="groupTitle" formControlName="name" required placeholder="Group Title" />
    </div>

    <!-- Select type -->
    <div class="form-group">
      <label for="type" class="form-label">Type <span style="color: red">*</span></label>
      <select class="form-select" id="type" formControlName="type">
        <option value="Deploy" selected>Deploy</option>
        <option value="Data Preparation">Data Preparation</option>
        <option value="Mobilizing LBU">Mobilizing LBU</option>
      </select>
    </div>

    <!-- Select country with flag -->
    <div class="form-group">
      <label for="country" class="form-label">Country <span style="color: red">*</span></label>
      <ng-select
        [items]="countries"
        bindLabel="name"
        bindValue="name"
        formControlName="country"
        [searchable]="true"
        class="select-country"
        [clearable]="false">
        <ng-template ng-option-tmp let-item="item">
          <img [src]="item.flag" width="20" height="12" alt="{{ item.name }}" />
          {{ item.name }}
        </ng-template>
      </ng-select>
    </div>

    <div class="form-group" *ngIf="productBranches.includes('RC')">
      <label for="site" class="form-label">Site</label>
      <select class="form-select" id="site" formControlName="site">
        <option *ngFor="let site of sitesOptions" [value]="site">{{site}}</option>
      </select>
    </div>

    <!-- Select status -->
    <div class="form-group">
      <label for="status" class="form-label">Status <span style="color: red">*</span></label>
      <select class="form-select" id="status" formControlName="status">
        <option *ngFor="let status of statusOptions" [value]="status">{{ status }}</option>
      </select>
    </div>

    <!-- Period selector (date range) -->
    <mat-form-field>
      <mat-label>Date <span style="color: red">*</span></mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Start date" formControlName="startDate" />
        <input matEndDate placeholder="End date" formControlName="endDate" />
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <div *ngIf="dateError" class="error-message">
      {{ dateError }}
    </div>

    <!-- Comment input -->
    <div class="form-group">
      <label for="comment" class="form-label">Comment</label>
      <textarea class="form-control" id="comment" rows="3" formControlName="comment"></textarea>
    </div>

    <!-- Notification Activation (Switch) -->
    <div class="form-group d-flex align-items-center">
      <div class="form-check form-switch">
        <label class="form-check-label" for="defaultSwitch1">Notification Activation</label>
        <input class="form-check-input" type="checkbox" id="defaultSwitch1" formControlName="notificationActivation" />
      </div>
    </div>

    <!-- Notification Date (Date Picker) -->
    <div class="form-group" *ngIf="addGroupForm.controls['notificationActivation'].value">
      <label for="notificationDate" class="form-label">Notification Date</label>
      <input type="date" class="form-control" id="notificationDate" formControlName="notificationDate" [min]="notificationDateMin" />
    </div>
  </form>
  <div *ngIf="isEditMode" class="history-section">
    <label class="form-label" for="history">History</label>
    <!-- Display creation date always -->
    <p>Group created on: {{ addGroupForm.get("creationDate")?.value | date : "dd/MM/yyyy" : "UTC" }}</p>
    <!-- Conditionally display modification history if available -->
    <ng-container *ngIf="groupHistory.length > 0">
      <ng-container *ngFor="let entry of groupHistory">
        <p>Initial period: {{ entry.previousStartDate | date : "dd/MM/yyyy" : "UTC" }} - {{ entry.previousEndDate | date : "dd/MM/yyyy" : "UTC" }}</p>
        <p>Date modified on: {{ entry.modificationDate | date : "dd/MM/yyyy" : "UTC" }}</p>
      </ng-container>
    </ng-container>
  </div>
  <div class="form-actions">
    <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onSubmit()"
      [disabled]="
        addGroupForm.invalid ||
        dateError ||
        saveInProgress ||
        (addGroupForm.controls['notificationActivation'].value && addGroupForm.controls['notificationDate'].value == '')
      ">
      Validate
    </button>
  </div>
</div>
