<div class="accordion">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" id="accordion-btn-header-usage">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#accordion-btn-collapse-' + section"
        aria-expanded="true"
        [attr.aria-controls]="'accordion-btn-collapse-' + section">
        <div class="container-left-accordion-title">
          <span class="material-icons-outlined color_red" aria-hidden="true">feedback</span>
          Squad
        </div>
      </button>
    </h4>

    <div [id]="'accordion-btn-collapse-' + section" class="accordion-collapse collapse show" aria-labelledby="accordion-btn-header-usage">
      <div class="accordion-body d-flex usage-container">
        <div class="squad-container">
          <div class="squad-profile-container">
            <h4>Achievements</h4>
            <div class="spinner-container">
              <div *ngIf="loading" class="spinner-border text-primary" output="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <div class="gauges-container">
              <div *ngFor="let sprint of sprintMetrics" class="gauge-{{ canvasId }}">
                <div
                  *ngIf="sprint.secondProductName && !loadingRepartitionGauges"
                  class="gauge-bar"
                  matTooltip="{{ sprint.repartitionProduct1 }}% {{ productName }}, {{ sprint.repartitionProduct2 }}% {{ sprint.secondProductName }}"
                  matTooltipPosition="below">
                  <div class="gauge-product" [style.width.%]="sprint.repartitionProduct1" style="background-color: #3339d0"></div>
                  <div class="gauge-product" [style.width.%]="sprint.repartitionProduct2" style="background-color: rgba(51, 57, 208, 0.5)"></div>
                </div>
              </div>
            </div>
            <div class="chart-container" [style]="hasData && !loading ? 'height: auto' : 'display:none'">
              <canvas [style]="hasData && !loading ? '' : 'height:0'" id="sprintChart-{{ canvasId }}"></canvas>
            </div>
            <span *ngIf="!hasData && !loading" class="no-data"> No Data. </span>
            <!-- Comment Section -->
            <div class="comment-section">
              <app-comment
                [title]="'Activity Comment'"
                [date]="comments['activity']?.lastUpdate"
                [comment]="comments['activity'].text"
                [isEditable]="isWriter || isAdmin"
                (saveComment)="saveComment('activity', $event)"></app-comment>
            </div>
          </div>
          <div class="activity-container">
            <h5 *ngIf="hasData && !loading">
              Activity <i class="sprints">{{ sprintMetrics[0]?.sprintName }} to {{ sprintMetrics[sprintMetrics.length - 1]?.sprintName }}</i>
            </h5>
            <div *ngIf="hasData && !loading" class="categories-container">
              <div class="category-container" *ngFor="let category of categories">
                <div class="category-label">
                  <b>{{ category.percentage | number : "1.0-1" }}%</b> {{ category.label }}
                </div>
                <div class="category-bar">
                  <div class="bar" [style.width.%]="category.percentage" [style.backgroundColor]="category.color"></div>
                </div>
              </div>
            </div>
            <!-- Comment Section -->
            <div class="comment-section">
              <app-comment
                [title]="'Staffing Comment'"
                [date]="comments['staffing']?.lastUpdate"
                [comment]="comments['staffing'].text"
                [isEditable]="isWriter || isAdmin"
                (saveComment)="saveComment('staffing', $event)"></app-comment>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
