export const branches: string[] = ["EP", "GRP", "HD", "HUT", "MS", "OT", "RC", "TGS", "TS"];

export const sites: string[] = [
  "Normandie - Raffinerie",
  "Normandie - Pétrochimie",
  "Normandie - Polymère",
  "Donges",
  "GrandPuits",
  "Laporte",
  "Carville",
  "Carling",
  "Feluy",
  "TEPA",
  "TERA",
  "TEOA",
  "Leuna",
  "La Mede",
  "Feyzin",
  "Port-Arthur",
];

export const domains: string[] = [
  "Enhanced Operations",
  "Supply Chain",
  "Production & Emissions",
  "Drilling & Wells",
  "Subsurface",
  "Project & Construction",
  "Availability & Integrity",
  "Supply Chain & Recycling",
  "HSE & Sustainability ",
  "Planning & Scheduling",
  "Process Optimization",
  "Polymers",
];

export const squadProfiles: string[] = ["Mono Product", "Multi Product", "Hybrid"];
