import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import countriesData from "../common/countries.json";
import {UserDTO} from "../common/user.service";
import {branches, domains, sites, squadProfiles} from "../common/util/const";
import {DateUtil} from "../common/util/date-util";
import {Product, ProductDetailsService} from "./product-details.service";
@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnChanges {
  productDetails: any = {};
  editForm: FormGroup = this.fb.group({});
  isEditMode: boolean = false;
  countries: Array<{code: string; name: string; flag: string}> = [];
  statusFields = [];
  viewFields = [];
  editableFields = [];

  @Input() isWriter: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() selectedProductId!: number;
  @Input() section!: string;

  branches = branches;
  lbus = [];
  domains = domains;
  squadProfiles = squadProfiles;
  sites = sites;

  constructor(private productDetailsService: ProductDetailsService, private fb: FormBuilder, private toastrService: ToastrService) {
    this.countries = Object.entries(countriesData).map(([code, name]) => ({
      code,
      name,
      flag: `assets/flags/${code.toLowerCase()}.svg`,
    }));

    this.lbus = this.countries.map((country) => country.name);

    // Initialize the reactive form
    this.editForm = this.fb.group({
      logo: "",
      name: "",
      branches: [],
      lbus: [],
      domain: [""],
      sponsors: [[]],
      productOwners: [[], Validators.required],
      domainLeads: [[]],
      itScaler: [[], Validators.required],
      changeCoordinators: [[]],
      squadProfile: ["", Validators.required],
      statusCreationDate: new Date().toISOString().split("T")[0],
      statusTransferDate: new Date().toISOString().split("T")[0],
      statusRunDate: new Date().toISOString().split("T")[0],
      statusDecommissioningDate: new Date().toISOString().split("T")[0],
      site: [""],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedProductId?.currentValue) {
      this.isEditMode = false;
      this.loadProductDetails();
    }
  }

  loadProductDetails(): void {
    this.productDetailsService.getProductDetails(this.selectedProductId).subscribe((data) => {
      this.productDetails = data;
      this.populateForm(data);

      const mappedLBUs = (this.productDetails?.lbus || []).map((lbu: string) => {
        const country = this.countries.find((c) => c.name === lbu);
        return country ? {name: country.name, flag: country.flag} : {name: lbu, flag: ""};
      });

      this.statusFields = [
        {
          label: "Creation",
          formControlName: "statusCreationDate",
          value: this.productDetails?.statusCreationDate || null,
        },
        {
          label: "Transfer",
          formControlName: "statusTransferDate",
          value: this.productDetails?.statusTransferDate || null,
        },
        {
          label: "Run",
          formControlName: "statusRunDate",
          value: this.productDetails?.statusRunDate || null,
        },

        {
          label: "Decommissioning",
          formControlName: "statusDecommissioningDate",
          value: this.productDetails?.statusDecommissioningDate || null,
        },
      ];

      this.viewFields = [
        {
          label: "Branch",
          value: this.getFormattedBranches(),
        },
        {
          label: "LBU",
          value: mappedLBUs.length > 0 ? mappedLBUs : "No Data",
        },
        {
          label: "Site",
          value: this.productDetails?.site || "No Data",
        },
        {
          label: "Domain",
          value: this.productDetails?.domain || "No Data",
        },
        {
          label: "Sponsor",
          value: this.getFormattedSponsors(),
        },
        {
          label: "Product Owner",
          value: this.getFormattedProductOwners(),
        },
        {
          label: "Domain/Asset Lead",
          value: this.getFormattedDomainLeads(),
        },
        {
          label: "IT Scaler",
          value: this.getFormattedITScaler(),
        },
        {
          label: "Change coordinators",
          value: this.getFormattedChangeCoordinators(),
        },
        {
          label: "Squad Profile",
          value: this.productDetails?.squadProfile || "No Data",
        },
      ];

      this.editableFields = [
        {
          label: "Branch",
          type: "ng-select",
          items: this.branches,
          formControlName: "branches",
          multiple: true,
          required: false,
          class: "select-control-multiple",
        },
        {
          label: "LBU",
          type: "ng-select",
          items: this.countries,
          formControlName: "lbus",
          multiple: true,
          required: false,
          class: "select-control-multiple",
        },
        {
          label: "Site",
          type: "ng-select",
          items: this.sites,
          formControlName: "site",
          multiple: false,
          required: false,
          class: "select-control",
        },
        {
          label: "Domain",
          type: "ng-select",
          items: this.domains,
          formControlName: "domain",
          multiple: false,
          required: true,
          class: "select-control",
        },
        {
          label: "Sponsor",
          type: "user-autocomplete",
          value: this.editForm.value.sponsors || [],
          formControlName: "sponsors",
          multiple: true,
          required: false,
          class: "select-control-multiple",
        },
        {
          label: "Product Owner",
          type: "user-autocomplete",
          value: this.editForm.value.productOwners || [],
          formControlName: "productOwners",
          multiple: true,
          required: false,
          class: "select-control-multiple",
        },
        {
          label: "Domain/Asset Lead",
          type: "user-autocomplete",
          value: this.editForm.value.domainLeads || [],
          formControlName: "domainLeads",
          multiple: true,
          required: false,
          class: "select-control-multiple",
        },
        {
          label: "IT Scaler",
          type: "user-autocomplete",
          value: this.editForm.value.itScaler ? [this.editForm.value.itScaler] : [],
          formControlName: "itScaler",
          multiple: false,
          required: false,
          class: "select-control",
        },
        {
          label: "Change Coordinator",
          type: "user-autocomplete",
          value: this.editForm.value.changeCoordinators || [],
          formControlName: "changeCoordinators",
          multiple: true,
          required: false,
          class: "select-control-multiple",
        },
        {
          label: "Squad Profile",
          type: "ng-select",
          items: this.squadProfiles,
          formControlName: "squadProfile",
          multiple: false,
          required: true,
          class: "select-control",
        },
      ];
    });
  }

  populateForm(details: Product): void {
    this.editForm.patchValue({
      logo: details.logo,
      name: details.name,
      branches: details.branches || [],
      lbus: details.lbus || [],
      domain: details.domain || "",
      sponsors: details.sponsors || [],
      productOwners: details.productOwners || [],
      domainLeads: details.domainLeads || [],
      itScaler: details.itScaler || null,
      changeCoordinators: details.changeCoordinators || [],
      squadProfile: details.squadProfile || "",
      statusCreationDate: details.statusCreationDate ? DateUtil.dateWithoutTimezone(details.statusCreationDate) : null,
      statusTransferDate: details.statusTransferDate ? DateUtil.dateWithoutTimezone(details.statusTransferDate) : null,
      statusRunDate: details.statusRunDate ? DateUtil.dateWithoutTimezone(details.statusRunDate) : null,
      statusDecommissioningDate: details.statusDecommissioningDate ? DateUtil.dateWithoutTimezone(details.statusDecommissioningDate) : null,
      site: details.site || "",
    });
  }

  toggleEditMode(enable: boolean = true): void {
    this.isEditMode = enable;
    if (!enable) {
      this.populateForm(this.productDetails); // Reset changes if edit mode is canceled
    }
  }

  saveChanges(): void {
    const updatedProduct: Product = {
      id: this.selectedProductId,
      name: this.editForm.value.name,
      logo: this.editForm.value.logo,
      branches: this.editForm.value.branches,
      lbus: this.editForm.value.lbus.length > 0 ? this.editForm.value.lbus.map((lbu: any) => lbu.name || lbu) : [],
      sponsors: this.editForm.value.sponsors,
      productOwners: this.editForm.value.productOwners,
      domainLeads: this.editForm.value.domainLeads,
      domain: this.editForm.value.domain || null,
      itScaler: this.editForm.value.itScaler[0] ? this.editForm.value.itScaler[0] || null : this.editForm.value.itScaler || null,
      changeCoordinators: this.editForm.value.changeCoordinators,
      squadProfile: this.editForm.value.squadProfile || null,
      statusCreationDate: this.editForm.value.statusCreationDate || null,
      statusTransferDate: this.editForm.value.statusTransferDate || null,
      statusRunDate: this.editForm.value.statusRunDate || null,
      statusDecommissioningDate: this.editForm.value.statusDecommissioningDate || null,
      site: this.editForm.value.site || null,
    };

    this.productDetailsService.updateProductDetails(this.selectedProductId, updatedProduct).subscribe(
      (response) => {
        this.toastrService.success("Product updated");
        this.loadProductDetails();
        this.isEditMode = false;
      },
      (error) => {
        this.toastrService.error("Failed to edit product");
      }
    );
  }

  /** FORMAT DATA METHODS **/
  getFormattedBranches(): string {
    return this.productDetails?.branches?.join(" & ") || "No Data";
  }

  getFormattedLBUs(): string {
    return this.productDetails?.lbus?.join(" & ") || "No Data";
  }

  getFormattedSponsors(): string {
    return this.productDetails?.sponsors?.map((sponsor) => sponsor.fullName).join(" & ") || "No Data";
  }

  getFormattedProductOwners(): string {
    return this.productDetails?.productOwners?.map((owner) => owner.fullName).join(" & ") || "No Data";
  }

  getFormattedDomainLeads(): string {
    return this.productDetails?.domainLeads?.map((domainLead) => domainLead.fullName).join(" & ") || "No Data";
  }

  getFormattedITScaler(): string {
    return this.productDetails?.itScaler?.fullName ? this.productDetails.itScaler.fullName : "No Data";
  }

  getFormattedChangeCoordinators(): string {
    return this.productDetails?.changeCoordinators?.map((changeCoordinator) => changeCoordinator.fullName).join(" & ") || "No Data";
  }

  onUsersSelected(selectedUsers: UserDTO[], key: string): void {
    this.editForm.patchValue({
      [key]: selectedUsers.map((user) => ({
        ...user,
      })),
    });
  }

  isFieldRequired(field: any): boolean {
    const control = this.editForm.get(field.formControlName);
    if (control?.validator) {
      const validator = control.validator({} as any);
      return !!validator?.required;
    }
    return false;
  }
}
