export const environment = {
  production: false,
  testing: false,
  webProtocol: 'https',
  serverUrl: 'azrdwbdeploycockpit.azurewebsites.net',
  clientId: '293413c4-b749-4e19-87ae-74a50581eb18',
  tenantId: '329e91b0-e21f-48fb-a071-456717ecc28e',
  appInsightsKey: '084eb8f3-d21a-4318-9218-5e133f13f252',
  hotjarSiteId: 5216515
};
