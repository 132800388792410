import {Component, ElementRef, Inject, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import countriesData from "../common/countries.json";
import {UserDTO} from "../common/user.service";
import {branches, domains, sites, squadProfiles} from "../common/util/const";
import {ProductService} from "../product/product.service";

@Component({
  selector: "app-add-product",
  templateUrl: "./add-product.component.html",
  styleUrls: ["./add-product.component.scss"],
})
export class AddProductComponent implements OnInit {
  @ViewChild("fileInput") fileInput!: ElementRef;
  addProductForm: FormGroup;
  branches = branches;
  lbu = [];
  sites = sites;
  domains = domains;
  squadProfiles = squadProfiles;
  showSiteField = false;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private productService: ProductService,
    public dialogRef: MatDialogRef<AddProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.addProductForm = this.fb.group({
      logo: [null],
      name: ["", Validators.required],
      branches: [],
      lbu: [],
      site: [""],
      domain: [""],
      sponsors: [[]],
      productOwners: [[], Validators.required],
      domainLeads: [[]],
      itScaler: ["", Validators.required],
      changeCoordinators: [[]],
      squadProfile: ["", Validators.required],
      statusCreationDate: new Date().toISOString().split("T")[0],
      statusTransferDate: null,
      statusRunDate: null,
      jiraKey: ["", Validators.required],
      cmdbKey: ["", Validators.required],
    });

    this.lbu = Object.entries(countriesData).map(([code, name]) => name);
  }

  ngOnInit(): void {
    this.addProductForm.get("branches")?.valueChanges.subscribe((selectedBranches) => {
      this.showSiteField = selectedBranches.some((branch: string) => branch === "RC");
    });
  }

  onSubmit(): void {
    if (this.addProductForm.valid) {
      const formValues = this.addProductForm.value;
      const newProduct = {
        logo: formValues.logo,
        name: formValues.name,
        branches: formValues.branches || [],
        lbus: formValues.lbu || [],
        site: formValues.site,
        domain: formValues.domain,

        // Transform sponsors into the required format
        sponsors: formValues.sponsors.map((user: UserDTO) => ({
          sponsor: {
            email: user.email,
            fullName: user.fullName,
          },
        })),

        // Transform productOwners into the required format
        productOwners: formValues.productOwners.map((user: UserDTO) => ({
          productOwner: {
            email: user.email,
            fullName: user.fullName,
          },
        })),

        // Transform domainLeads into the required format
        domainLeads: formValues.domainLeads.map((user: UserDTO) => ({
          domainLead: {
            email: user.email,
            fullName: user.fullName,
          },
        })),
        itScaler: formValues.itScaler
          ? {
              email: formValues.itScaler.email,
              fullName: formValues.itScaler.fullName,
            }
          : null,
        changeCoordinators: formValues.changeCoordinators.map((user: UserDTO) => ({
          changeCoordinator: {
            email: user.email,
            fullName: user.fullName,
          },
        })),
        squadProfile: formValues.squadProfile,
        statusCreationDate: formValues.statusCreationDate,
        statusTransferDate: formValues.statusTransferDate,
        statusRunDate: formValues.statusRunDate,
        jiraProjectKey: formValues.jiraKey,
        finopsBsName: formValues.cmdbKey,
      };
      this.productService.addProduct(newProduct).subscribe(
        (response) => {
          this.toastr.success("Product added successfully.", "Success");
          this.dialogRef.close(newProduct);
        },
        (err) => {
          this.toastr.error("Failed to add product. Try again.", "Error");
        }
      );
    } else {
      this.toastr.error("Please fill out the form correctly.", "Error");
    }
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onUsersSelected(selectedUsers: UserDTO[], key: string): void {
    if (key === "itScaler") {
      const selectedUser = selectedUsers.length > 0 ? selectedUsers[0] : null;
      this.addProductForm.patchValue({
        [key]: selectedUser,
      });
    } else {
      this.addProductForm.patchValue({
        [key]: selectedUsers.map((user) => ({
          ...user,
        })),
      });
    }
  }

  triggerFileUpload(): void {
    this.fileInput.nativeElement.click();
  }

  handleKeyPress(event: KeyboardEvent): void {
    if (event.key === "Enter") {
      this.triggerFileUpload();
    }
  }

  onLogoChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.addProductForm.patchValue({
          logo: reader.result as string,
        });
      };
      reader.readAsDataURL(file);
    }
  }
}
