import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  private baseUrl = `${environment.webProtocol}://${environment.serverUrl}/api/products`;

  constructor(private http: HttpClient) {}

  addProduct(product: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/add`, product);
  }
}
