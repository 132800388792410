import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private apiUrl = `${environment.webProtocol}://${environment.serverUrl}/api/notification`;

  constructor(private http: HttpClient) {
  }

  invertNotificationRead(notificationId: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams().set("notificationId", notificationId);
    return this.http.get(`${this.apiUrl}/invert-read`, {params: httpParams});
  }

  markAsRead(notificationId: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams().set("notificationId", notificationId);
    return this.http.get(`${this.apiUrl}/mark-as-read`, {params: httpParams});
  }

  deleteNotification(notificationId: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams().set("notificationId", notificationId);
    return this.http.get(`${this.apiUrl}/delete-notification`, {params: httpParams});
  }

  notificationsOpened(): Observable<any> {
    return this.http.get(`${this.apiUrl}/notifications-opened`);
  }
}
