import {enableProdMode} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";

import {AppModule} from "./app/app.module";
import {environment} from "./environments/environment";
import Hotjar from "@hotjar/browser";
import {MsalInterceptor} from "@azure/msal-angular";
import {PublicClientApplication} from "@azure/msal-browser";

if (environment.production) {
  enableProdMode();
}

if (environment.hotjarSiteId) {
  Hotjar.init(environment.hotjarSiteId, 6);
}

if (environment.testing) {
  MsalInterceptor.prototype.intercept = (req, next) => {
    // Generating a fake JWT token with the mocked user

    const header = {
      "alg": "HS256",
      "typ": "JWT"
    }
    const encodedHeaders = btoa(JSON.stringify(header));

    let userMocked = localStorage.getItem("userMocked");

    const claims = {
      "unique_name": JSON.parse(userMocked).username
    }

    let encodedPayload = btoa(JSON.stringify(claims));

    // Magic code to remove a failed encoding
    if (encodedPayload.endsWith("==")) encodedPayload = encodedPayload.slice(0, encodedPayload.length - 2);
    else if (encodedPayload.endsWith("=")) encodedPayload = encodedPayload.slice(0, encodedPayload.length - 1);

    const encodedSignature = btoa("not_implemented");

    let jwt = `${encodedHeaders}.${encodedPayload}.${encodedSignature}`;

    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${jwt}`
      }
    });
    return next.handle(req);
  };

  PublicClientApplication.prototype.getAllAccounts = (): any => {
    if (!localStorage.getItem('userMocked')) return undefined;

    let user = localStorage.getItem('userMocked');
    return [
      JSON.parse(user)
    ]
  };
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
