import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {AuthenticationService} from "../common/authentication.service";
import {SharedStateService} from "../common/shared-state.service";
import {UserService} from "../common/user.service";

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html"
})
export class ProductComponent implements OnInit {
  productId: number;
  productName: string;
  isWriter: string[] = [];
  isAdmin: boolean = false;
  showHiddenComponent = false;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthenticationService,
    private sharedStateService: SharedStateService
  ) {}

  ngOnInit(): void {
    // Get product ID from active route
    this.route.params.subscribe((params) => {
      this.productId = +params["id"]; // Convert ID in number
      this.productName = params["name"];
      this.checkRights();
    });
    this.isAdmin = this.userService.getUserIsAdmin();

    this.sharedStateService.showHiddenComponent$.subscribe((show) => {
      this.showHiddenComponent = show;
    });
  }

  checkRights() {
    this.userService.editRightsBySection(this.authService.email, this.productId).subscribe((result: string[]) => {
      this.isWriter = result;
    });
  }

  // Replace spaces by "-"
  toIdFormat(section: string): string {
    return section.replace(/\s+/g, "-");
  }
}
