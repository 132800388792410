import {CommonModule} from "@angular/common";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatButtonModule} from "@angular/material/button";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatChipsModule} from "@angular/material/chips";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
  MatNativeDateModule
} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatMenuModule} from "@angular/material/menu";
import {MatRadioModule} from "@angular/material/radio";
import {MatSelectModule} from "@angular/material/select";
import {MatTooltipModule} from "@angular/material/tooltip";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalRedirectComponent,
  MsalService,
} from "@azure/msal-angular";
import {NgSelectModule} from "@ng-select/ng-select";
import {DndModule} from "ngx-drag-drop";
import {NgxEditorModule} from "ngx-editor";
import {ToastrModule} from "ngx-toastr";
import {ActionPlanComponent} from "./action-plan/action-plan.component";
import {ManageActionComponent} from "./action-plan/manage-action/manage-action.component";
import {AddEventComponent} from "./add-event/add-event.component";
import {AddGroupComponent} from "./add-group/add-group.component";
import {AddProductComponent} from "./add-product/add-product.component";
import {AlertComponent} from "./alert/alert.component";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {CommentComponent} from "./common/comment/comment.component";
import {ConfirmPopupComponent} from "./common/confirm-popup/confirm-popup.component";
import {msalGuardConfigFactory} from "./common/msal/msal-guard-config-factory";
import {msalInstanceFactory} from "./common/msal/msal-instance-factory";
import {msalInterceptorConfigFactory} from "./common/msal/msal-interceptor-config-factory";
import {PopupHeaderComponent} from "./common/popup-header/popup-header.component";
import {UserAutocompleteComponent} from "./common/user-autocomplete/user-autocomplete.component";
import {SafeHtmlPipe} from "./common/util/safe-html-pipe";
import {EightPillarsComponent} from "./eight-pillars/eight-pillars.component";
import {PillarCommentComponent} from "./eight-pillars/pillar-comment/pillar-comment.component";
import {FinopsComponent} from "./finops/finops.component";
import {FocusComponent} from "./focus/focus.component";
import {HeaderComponent} from "./header/header.component";
import {HighlightsComponent} from "./highlights/highlights.component";
import {PopoverComponent} from "./popover/popover.component";
import {ProductDetailsComponent} from "./product-details/product-details.component";
import {ProductComponent} from "./product/product.component";
import {RoadmapComponent} from "./roadmap/roadmap.component";
import {SearchProductComponent} from "./search-product/search-product.component";
import {SquadComponent} from "./squad/squad.component";
import {AddToolboxItemComponent} from "./toolbox/add-toolbox-item/add-toolbox-item.component";
import {EditDeleteToolboxItemComponent} from "./toolbox/edit-delete-toolbox-item/edit-delete-toolbox-item.component";
import {ToolboxComponent} from "./toolbox/toolbox.component";
import {UsageComponent} from "./usage/usage.component";
import {NgbPopoverModule} from "@ng-bootstrap/ng-bootstrap";

export const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "DD/MM/YYYY",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ProductDetailsComponent,
    PopoverComponent,
    ProductComponent,
    RoadmapComponent,
    SearchProductComponent,
    AlertComponent,
    AddEventComponent,
    AddGroupComponent,
    EightPillarsComponent,
    PopupHeaderComponent,
    PillarCommentComponent,
    ConfirmPopupComponent,
    ActionPlanComponent,
    ManageActionComponent,
    HighlightsComponent,
    UserAutocompleteComponent,
    ToolboxComponent,
    AddToolboxItemComponent,
    EditDeleteToolboxItemComponent,
    UsageComponent,
    FocusComponent,
    SafeHtmlPipe,
    SquadComponent,
    CommentComponent,
    FinopsComponent,
    AddProductComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatCheckboxModule,
    MatButtonModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-left",
    }),
    MatMenuModule,
    MatRadioModule,
    MatTooltipModule,
    MatDialogModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    FormsModule,
    NgSelectModule,
    MatTooltipModule,
    DndModule,
    MatButtonToggleModule,
    NgxEditorModule,
    NgbPopoverModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory,
    },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
    {provide: MatDialogRef, useValue: {}},
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
