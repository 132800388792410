import {Component, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import * as htmlToImage from "html-to-image";
import {Subscription} from "rxjs";
import {AuthenticationService} from "../common/authentication.service";
import {SharedEventsService} from "../common/shared-events-service";
import {SharedStateService} from "../common/shared-state.service";
import {ProductDTO, UserService} from "../common/user.service";
import moment from "moment";
import {NotificationService} from "../notification/notification.service";
import {ToastrService} from "ngx-toastr";
import {ProductDetailsService} from "../product-details/product-details.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  userProducts: ProductDTO[] = [];
  selectedProductId: number;
  temporaryProduct: ProductDTO | null = null;
  isAllLoaded = true;
  loading: boolean = false;
  public username: string;
  private loadingSubscription: Subscription | null = null;

  @ViewChild('notificationMenuRef') notificationMenuRef;

  seeMore: boolean;
  notifications: any;
  notificationsToDisplay: any;
  notificationDisplaySelected: number = 0;
  lastOpenedNotifications: number;
  notificationsNotOpenedCount: number = 0;

  constructor(
    public authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedEventsService: SharedEventsService,
    public sharedStateService: SharedStateService,
    private notificationService: NotificationService,
    private toastrService: ToastrService,
    private productDetailsService: ProductDetailsService
  ) {
  }

  ngOnInit(): void {
    if (this.authenticationService.userIsAuthenticated) {
      this.username = this.authenticationService.username;
      this.loadUserProducts();

      this.sharedEventsService.reloadHeader$.subscribe(() => {
        this.loadUserProducts();
      });

      this.sharedEventsService.selectedProduct$.subscribe((product) => {
        if (!this.userProducts.some((userProduct) => userProduct.id === product.id)) {
          this.temporaryProduct = product;
        }
      });

      this.userService.getLastOpenedNotifications().subscribe((lastOpened) => {
        this.lastOpenedNotifications = lastOpened.result;
        this.userService.getNotifications().subscribe((notifications) => {
          this.notifications = notifications;
          this.notificationsToDisplay = notifications;
          this.notificationsNotOpenedCount = this.notifications.filter(notification => notification.date > this.lastOpenedNotifications).length;
        });
      });
    }
  }

  loadUserProducts() {
    this.userService.getProductsForUserWithEditRights(this.authenticationService.email).subscribe(
      (products) => {
        this.userProducts = products;

        if (products.length === 0) {
          this.router.navigate(["/search"]);
          return;
        }

        // Redirect to first product if no route active
        if (products.length > 0 && !this.isOnProductRoute()) {
          const firstProduct = products[0];
          this.router.navigate(["/product", firstProduct.name, firstProduct.id]); // Redirect to first product
          this.selectedProductId = firstProduct.id;
        } else {
          const productIdParam = this.route.snapshot.firstChild?.paramMap.get("id");
          this.selectedProductId = parseInt(productIdParam, 10);

          if (!this.userProducts.some((userProduct) => userProduct.id === this.selectedProductId)) {
            this.productDetailsService.getBaseProductInfo(this.selectedProductId).subscribe((product) => {
              this.temporaryProduct = product;
            });
          }
        }
      },
      (error) => {
        console.error("Error when getting the products :", error);
      }
    );
  }

  onProductSelect(productId: number): void {
    this.selectedProductId = productId;
    this.temporaryProduct = null;
  }

  onSearchClick(): void {
    this.temporaryProduct = null;
  }

  // Check if user is already seeing a product
  isOnProductRoute(): boolean {
    const currentRoute = this.route.snapshot.firstChild; // Get active route
    // Check if the active route is a 'product' route
    return currentRoute?.routeConfig?.path?.includes("product") || currentRoute?.routeConfig?.path?.includes("search") || false;
  }

  saveThisDPR(): void {
    this.loading = true;
    this.sharedStateService.clearLoadingStatus();
    this.sharedStateService.setShowHiddenComponent(true);
    this.loadingSubscription = this.sharedStateService.isLoading$.subscribe((loadingState) => {
      this.isAllLoaded = Object.values(loadingState).every((status) => status === false) && Object.keys(loadingState).length > 0;
      if (this.isAllLoaded) {
        if (this.loadingSubscription) {
          this.loadingSubscription.unsubscribe();
        }
        const element = document.body;
        const hiddenComponent = document.getElementById("hidden-component");

        hiddenComponent.style.visibility = "visible";
        hiddenComponent.style.height = "auto";

        const visibleComponent = document.getElementById("visible-component");

        visibleComponent.style.visibility = "hidden";
        visibleComponent.style.height = "0";

        const noExportElements = document.getElementsByClassName("no-export");
        Array.from(noExportElements).forEach((element) => {
          (element as HTMLElement).style.display = "none";
        });

        htmlToImage
          .toPng(element, {
            quality: 1,
            filter: (domNode) =>
              domNode.className == null || domNode.className.indexOf("no-export") == -1 || domNode.className.indexOf("no-export-loader") == -1,
          })
          .then((dataUrl) => {
            const selectedProduct = this.temporaryProduct || this.userProducts.find((product) => product.id === this.selectedProductId);
            const branches = selectedProduct.branches?.map((branch) => branch.branchName).join("&") || "";

            const productName = selectedProduct.name || "";
            const currentDate = new Date().toISOString().split("T")[0];

            const link = document.createElement("a");
            link.href = dataUrl;
            link.download = `${currentDate} DPR ${branches} ${productName}.png`;
            link.click();

            if (hiddenComponent) {
              hiddenComponent.style.visibility = "hidden";
              hiddenComponent.style.height = "0";
            }

            visibleComponent.style.visibility = "visible";
            visibleComponent.style.height = "auto";

            Array.from(noExportElements).forEach((element) => {
              (element as HTMLElement).style.removeProperty("display");
            });

            this.sharedStateService.setShowHiddenComponent(false);
            this.loading = false;
          })
          .catch((error) => {
            console.error("Error during the screen capture :", error);
            hiddenComponent.style.visibility = "hidden";
            hiddenComponent.style.height = "0";

            visibleComponent.style.visibility = "visible";
            visibleComponent.style.height = "auto";

            Array.from(noExportElements).forEach((element) => {
              (element as HTMLElement).style.removeProperty("display");
            });

            this.sharedStateService.setShowHiddenComponent(false);
            this.loading = false;
          });
      }
    });
  }

  getDateDiff(notificationDate: number) {
    let daysDiff = Math.floor(moment().diff(moment(notificationDate)) / 1000 / 60 / 60 / 24);
    return daysDiff == 0 ? "today" : daysDiff + "d";
  }

  updateNotificationsDisplayed(optionSelected: number) {
    this.notificationDisplaySelected = optionSelected;
    this.notificationsToDisplay = optionSelected == 1 ? this.notifications.filter(notif => !notif.read) : this.notifications;
  }

  invertNotificationRead(notification: any) {
    this.notificationService.invertNotificationRead(notification.id).subscribe(() => {
      notification.read = !notification.read;
      this.notificationsToDisplay = this.notificationDisplaySelected == 1 ? this.notifications.filter(notif => !notif.read) : this.notifications;
    }, () => {
      this.toastrService.error("An error occurred.");
    });
  }

  deleteNotification(notification: any) {
    this.notificationService.deleteNotification(notification.id).subscribe(() => {
      this.notifications.splice(this.notifications.indexOf(notification), 1);
    }, () => {
      this.toastrService.error("An error occurred.");
    });
  }

  notificationsOpened() {
    this.notificationService.notificationsOpened().subscribe(() => {
      this.lastOpenedNotifications = new Date().valueOf();
      this.notificationsNotOpenedCount = 0;
    });
  }

  goToNotification(notification) {
    this.notificationMenuRef.close();
    this.onProductSelect(notification.productId);
    this.notificationService.markAsRead(notification.id).subscribe(() => {
      notification.read = true;
    });

    if (notification.topic.startsWith("actionPlanReminder")) {
      this.router.navigate(["/product", notification.productName, notification.productId], {fragment: "action-plan"});
    } else if (notification.topic == "eventReminder" || notification.topic == "groupReminder") {
      this.router.navigate(["/product", notification.productName, notification.productId]);

      let fragment = "roadmap-" + (notification.topic.startsWith("event") ? "event" : "group");
      if (notification.topic.startsWith("event")) fragment += "-" + notification.roadmapEvent.id
      else fragment += "-" + notification.roadmapGroup.id

      this.router.navigate(["/product", notification.productName, notification.productId],
        {
          fragment: fragment
        });
    }
  }

  seeMoreClicked() {
    this.seeMore = true;
    this.updateNotificationsDisplayed(this.notificationDisplaySelected);
  }
}
