import {getMentionsPlugin} from "prosemirror-mentions";
import {Plugin} from "prosemirror-state";
import countriesData from "../../common/countries.json";

const countries = Object.entries(countriesData).map(([code, name], index) => {
  const email = "";
  return {
    id: index + 1,
    code,
    name,
    email,
    flag: `assets/flags/${code.toLowerCase()}.svg`,
  };
});

const mentionPlugin = getMentionsPlugin({
  getSuggestions: (type, text, done) => {
    setTimeout(() => {
      if (type === "mention") {
        // Filter country list based on user input
        done(
          countries.filter((country) => country.name.toLowerCase().includes(text.toLowerCase())).splice(0, 5) // Limit to 5 results
        );
      }
    }, 0);
  },
  getSuggestionsHTML: (items, type) => {
    if (type === "mention") {
      // Generate HTML for the mention suggestions
      return (
        '<div class="suggestion-item-list">' +
        items
          .map(
            (item) => `<div class="suggestion-item"><img src="${item.flag}" alt="${item.code}" class="suggestion-flag" />
            <span>${item.name}</span></div>`
          )
          .join("") +
        "</div>"
      );
    }
    return null;
  },
});

const plugins: Plugin[] = [mentionPlugin];

export default plugins;
