import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {ProductDTO, UserDTO} from "../common/user.service";

export interface Product {
  id: number;
  name: string;
  logo: string;
  productOwners: UserDTO[];
  branches: string[];
  lbus: string[];
  sponsors: UserDTO[];
  domainLeads: UserDTO[];
  domain: string | null;
  itScaler: UserDTO;
  changeCoordinators: UserDTO[];
  squadProfile: string | null;
  statusCreationDate: Date | null;
  statusTransferDate: Date | null;
  statusRunDate: Date | null;
  statusDecommissioningDate: Date | null;
  site: string | null;
}

@Injectable({
  providedIn: "root",
})
export class ProductDetailsService {
  private apiUrl = `${environment.webProtocol}://${environment.serverUrl}/api/products`;

  constructor(private http: HttpClient) {}

  getBaseProductInfo(productId: number): Observable<ProductDTO> {
    return this.http.get<ProductDTO>(`${this.apiUrl}/base-product-info`, {
      params: {productId},
    });
  }

  getProductDetails(productId: number): Observable<Product> {
    return this.http.get<Product>(`${this.apiUrl}/${productId}`);
  }

  updateProductDetails(id: number, details: Product): Observable<any> {
    return this.http.post(`${this.apiUrl}/update/${id}`, details);
  }

  getBranchesOfProduct(productId: number): Observable<Array<string>> {
    let httpParams: HttpParams = new HttpParams().set("productId", productId);
    return this.http.get<Array<string>>(`${this.apiUrl}/branches`, {params: httpParams});
  }
}
