<popup-header [dialogRef]="dialogRef" icon="calendar_today" text="{{ title }}"></popup-header>
<div class="add-event-dialog">
  <form [formGroup]="addEventForm">
    <!-- Input title -->
    <div class="form-event">
      <label for="eventTitle" class="form-label">Event Title <span style="color: red">*</span></label>
      <input type="text" class="form-control" id="eventTitle" formControlName="name" required placeholder="Event Title" />
    </div>

    <!-- Select type -->
    <div class="form-event">
      <label class="form-label" for="type">Type <span style="color: red">*</span></label>
      <select class="form-select" id="type" formControlName="type">
        <option value="Mobilizing LBUs">Mobilizing LBUs</option>
        <option value="Product Owner">Product Owner</option>
        <option value="Interface with IT">Interface with IT</option>
        <option value="Expert Metier support">Expert Metier support</option>
        <option value="Data Preparation">Data Preparation</option>
        <option value="Product Adaptation">Product Adaptation</option>
        <option value="Data Science">Data Science</option>
        <option value="Change Adoption">Change Adoption</option>
      </select>
    </div>

    <!-- Select associated group -->
    <div class="form-event">
      <label for="group" class="form-label">Group <span style="color: red">*</span></label>
      <select class="form-select" id="group" formControlName="roadmapGroupId">
        <!-- Use group id to display the name -->
        <option *ngFor="let group of groups_list" [value]="group.id">
          {{ group.name }}
        </option>
      </select>
    </div>

    <!-- Select priority -->
    <div class="form-event">
      <label class="form-label" for="priority">Priority <span style="color: red">*</span></label>
      <select class="form-select" id="priority" formControlName="priority">
        <option value="High">High</option>
        <option value="Low">Low</option>
        <option value="Medium">Medium</option>
      </select>
    </div>

    <!-- Select status -->
    <div class="form-event">
      <label for="status" class="form-label">Status <span style="color: red">*</span></label>
      <select class="form-select" id="status" formControlName="status">
        <option value="Ready">Ready</option>
        <option value="On going">On going</option>
        <option value="Done">Done</option>
      </select>
    </div>

    <!-- Period selector (date range) -->
    <mat-form-field>
      <mat-label>Date range <span style="color: red">*</span></mat-label>
      <mat-date-range-input [rangePicker]="picker" [min]="minStartDate" [max]="maxEndDate" color="primary">
        <input matStartDate placeholder="Start date" formControlName="startDate" />
        <input matEndDate placeholder="End date" formControlName="endDate" />
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <!-- Input comment -->
    <div class="form-event">
      <label for="comment" class="form-label">Comment</label>
      <textarea class="form-control" id="comment" rows="3" formControlName="comment"></textarea>
    </div>

    <!-- Notification Activation (Switch) -->
    <div class="form-event d-flex align-items-center">
      <div class="form-check form-switch">
        <label class="form-check-label" for="defaultSwitch1">Notification Activation</label>
        <input class="form-check-input" type="checkbox" id="defaultSwitch1" formControlName="notificationActivation" />
      </div>
    </div>

    <!-- Notification Date (Date Picker) -->
    <div class="form-event" *ngIf="addEventForm.controls['notificationActivation'].value">
      <label for="notificationDate" class="form-label">Notification Date</label>
      <input type="date" class="form-control" id="notificationDate" formControlName="notificationDate" [min]="notificationDateMin" />
    </div>
  </form>
  <!-- History Section -->
  <div *ngIf="isEditMode" class="history-section">
    <label class="form-label" for="history">History</label>
    <!-- Display creation date always -->
    <p>Event created on: {{ addEventForm.get("creationDate")?.value | date : "dd/MM/yyyy" : "UTC" }}</p>
    <!-- Conditionally display modification history if available -->
    <ng-container *ngIf="eventHistory.length > 0">
      <ng-container *ngFor="let entry of eventHistory">
        <p>Initial period: {{ entry.previousStartDate | date : "dd/MM/yyyy" : "UTC" }} - {{ entry.previousEndDate | date : "dd/MM/yyyy" : "UTC" }}</p>
        <p>Date modified on: {{ entry.modificationDate | date : "dd/MM/yyyy" : "UTC" }}</p>
      </ng-container>
    </ng-container>
  </div>

  <div class="form-actions">
    <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onSubmit()"
      [disabled]="
        addEventForm.get('name')?.invalid ||
        saveInProgress ||
        (addEventForm.controls['notificationActivation'].value && addEventForm.controls['notificationDate'].value == '')
      ">
      Validate
    </button>
  </div>
</div>
