<div class="accordion">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" id="accordion-btn-header-usage">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#accordion-btn-collapse-' + section"
        aria-expanded="true"
        [attr.aria-controls]="'accordion-btn-collapse-' + section">
        <div class="container-left-accordion-title">
          <span class="material-icons-outlined color_red" aria-hidden="true">savings</span>
          FinOps
        </div>
      </button>
      <div class="container-right">
        <a
          href="https://digitalplatforms.totalenergies.com/dashboards/finops-dashboard"
          target="_blank"
          class="finops-dashboard-link no-export"
          rel="noopener">
          <span class="material-icons-outlined" matTooltip="Go to the FinOps dashboard">launch</span>
        </a>
      </div>
    </h4>

    <div [id]="'accordion-btn-collapse-' + section" class="accordion-collapse collapse show" aria-labelledby="accordion-btn-header-usage">
      <div class="accordion-body d-flex usage-container">
        <div class="finops-container">
          <div class="finops-graph-container">
            <h4>Top 10 - Application</h4>
            <div class="spinner-container">
              <div *ngIf="loading" class="spinner-border text-primary" output="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <div class="chart-container" [style]="!loading && hasData ? 'height: 50vh' : 'display:none'">
              <canvas id="finopsChart-{{ canvasId }}"></canvas>
            </div>
            <div *ngIf="!hasData && !loading" style="font-style: italic; margin-top: 20px">
              No Data (the CMDB Business Service for this product has not been filled or is incorrect).
            </div>
          </div>
          <!-- Comment Section -->
          <div class="comment-section" [style]="hasData && !loading ? 'margin-top: 84px;' : ''">
            <app-comment
              [title]="'ISDL / ITS Comment'"
              [date]="lastUpdate"
              [comment]="comment"
              [isEditable]="isWriter || isAdmin"
              (saveComment)="saveComment($event)"></app-comment>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
