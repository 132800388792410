import {Component, ViewChild} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {MatMenu} from "@angular/material/menu";
import {MsalService} from "@azure/msal-angular";
import {AddProductComponent} from "../add-product/add-product.component";

@Component({
  selector: "app-popover",
  templateUrl: "./popover.component.html",
  styleUrls: ["./popover.component.scss"],
})
export class PopoverComponent {
  @ViewChild("profileMenu", {static: true}) profileMenu: MatMenu;

  constructor(public authService: MsalService, private dialog: MatDialog) {}

  logout(): void {
    this.authService.logout();
  }

  openAddProductDialog(): void {
    this.dialog.open(AddProductComponent, {
      width: "600px",
      data: {},
    });
  }
}
