<div class="accordion no-export">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" [id]="'accordion-btn-header-' + section">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#accordion-btn-collapse-' + section"
        aria-expanded="true"
        [attr.aria-controls]="'accordion-btn-collapse-' + section">
        <div class="container-left-accordion-title">
          <span class="material-icons-outlined color_red" aria-hidden="true" color="accent">
            {{ "handyman" }}
          </span>
          {{ "Toolbox" }}
        </div>
      </button>
    </h4>
    <div [id]="'accordion-btn-collapse-' + section" class="accordion-collapse show" [attr.aria-labelledby]="'accordion-btn-header-' + section">
      <div class="accordion-body">
        <div class="toolbox-line">
          <div class="line-left">Product links</div>
          <div class="line-right">
            <div *ngFor="let link of productLinks" class="link-container">
              <a [href]="link.link" target="_blank" class="link-link">
                <span *ngIf="getIconForLink(link.title) != null" class="material-icons-outlined link-icon" aria-hidden="true">{{
                  getIconForLink(link.title)
                }}</span>
                <span class="link-text">{{ link.title }}</span>
              </a>
            </div>

            <span *ngIf="isWriter || isAdmin" class="material-icons-outlined edit-item-menu" aria-hidden="true" [matMenuTriggerFor]="menuLinks"
              >more_horiz</span
            >
            <mat-menu #menuLinks="matMenu">
              <button mat-menu-item class="menu-item" (click)="addItem('link')">Add a link</button>
              <button mat-menu-item class="menu-item" (click)="modifyItem('link', false)">Edit a link</button>
              <button mat-menu-item class="menu-item" (click)="modifyItem('link', true)">Delete a link</button>
            </mat-menu>
          </div>
        </div>
        <div class="margin-top-12" style="border-top: 1px solid rgb(222, 226, 230)"></div>
        <div class="toolbox-line margin-top-12">
          <div class="line-left">Communication - One Pager & Communication Kit Files</div>
          <div class="line-right">
            <div *ngFor="let document of productDocuments" class="link-container">
              <button type="button" class="btn btn-secondary save-dpr-button" (click)="getDocument(document)">
                <span class="material-icons-outlined" aria-hidden="true">file_download</span>
                {{ document.title }}
              </button>
            </div>
            <span *ngIf="isWriter || isAdmin" class="material-icons-outlined edit-item-menu" aria-hidden="true" [matMenuTriggerFor]="menuDocuments"
              >more_horiz</span
            >
            <mat-menu #menuDocuments="matMenu">
              <button mat-menu-item class="menu-item" (click)="addItem('document')">Add a document</button>
              <button mat-menu-item class="menu-item" (click)="modifyItem('document', false)">Edit a document</button>
              <button mat-menu-item class="menu-item" (click)="modifyItem('document', true)">Delete a document</button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
