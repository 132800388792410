import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {SimpleComment} from "../common/comment/comment.interface";

@Injectable({
  providedIn: "root",
})
export class FinopsService {
  private apiUrl: string = `${environment.webProtocol}://${environment.serverUrl}/api/finops`;

  constructor(private http: HttpClient) {}

  getFinOpsByProduct(productId: number): Observable<any> {
    let httpParams: HttpParams = new HttpParams().set("productId", productId);

    return this.http.get<any>(`${this.apiUrl}/get`, {params: httpParams});
  }

  saveComment(productId: number, comment: string): Observable<SimpleComment> {
    return this.http.post<SimpleComment>(`${this.apiUrl}/comments`, {productId, comment});
  }

  getComment(productId: number): Observable<SimpleComment> {
    return this.http.get<SimpleComment>(`${this.apiUrl}/comments/${productId}`);
  }
}
