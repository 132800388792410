import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";

export interface Highlight {
  id: number;
  description: string;
  originalDescription?: string;
  creationDate: Date;
  product: {id: number};
  isEditing?: boolean;
}

export interface HighlightRes {
  highlights: Highlight[];
  highlightLastUpdateDate: Date;
}

@Injectable({
  providedIn: "root",
})
export class HighlightService {
  private baseUrl = `${environment.webProtocol}://${environment.serverUrl}/api/highlights`;

  constructor(private http: HttpClient) {}

  getHighlightsByProductId(productId: number): Observable<HighlightRes> {
    return this.http.get<HighlightRes>(`${this.baseUrl}/${productId}`);
  }

  deleteHighlight(highlightId: number): Observable<void> {
    return this.http.get<void>(`${this.baseUrl}/delete/${highlightId}`);
  }

  addHighlight(highlight: Highlight): Observable<Highlight> {
    return this.http.post<Highlight>(`${this.baseUrl}/add`, highlight);
  }

  updateHighlight(highlight: Highlight): Observable<Highlight> {
    return this.http.post<Highlight>(`${this.baseUrl}/update/${highlight.id}`, highlight);
  }
}
