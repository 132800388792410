<div class="accordion" id="product-details-container">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" [id]="'accordion-btn-header-' + section">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#accordion-btn-collapse-' + section"
        aria-expanded="true"
        [attr.aria-controls]="'accordion-btn-collapse-' + section">
        <div class="container-left-accordion-title">
          <img *ngIf="productDetails.logo; else defaultLogo" [src]="productDetails.logo" alt="{{ productDetails.name }}" class="product-logo" />
          <ng-template #defaultLogo>
            <span class="material-icons-outlined color_red" aria-hidden="true" color="accent"> devices_fold </span>
          </ng-template>
          {{ productDetails?.name }}
        </div>
      </button>
      <div class="container-right">
        <span class="last-update-text" *ngIf="productDetails?.productDetailsLastUpdateDate">
          Last Update: {{ productDetails.productDetailsLastUpdateDate | date : "dd/MM/yyyy" : "UTC" }}
        </span>
        <div class="additional-btn no-export" *ngIf="isWriter || isAdmin">
          <button *ngIf="!isEditMode" type="button" class="btn btn-secondary" data-cypress="edit-button" (click)="toggleEditMode()">
            <span class="material-icons-outlined" aria-hidden="true">edit</span>Edit
          </button>
          <button *ngIf="isEditMode" type="button" class="btn btn-sm btn-outline btn-circle btn-tertiary" (click)="saveChanges()">
            <span class="material-icons-outlined" aria-hidden="true">check</span>
          </button>
          <button *ngIf="isEditMode" type="button" class="btn btn-sm btn-outline btn-circle btn-tertiary" (click)="toggleEditMode(false)">
            <span class="material-icons-outlined" aria-hidden="true">close</span>
          </button>
        </div>
      </div>
    </h4>
    <div [id]="'accordion-btn-collapse-' + section" class="accordion-collapse show" [attr.aria-labelledby]="'accordion-btn-header-' + section">
      <div class="accordion-body">
        <!-- Form Section -->
        <form class="row" *ngIf="isEditMode; else viewMode" [formGroup]="editForm">
          <!-- Editable Fields -->
          <div class="col-md-9">
            <div class="row">
              <ng-container *ngFor="let field of editableFields">
                <div
                  class="col-md-3 mb-2 product-details-field"
                  *ngIf="field.label != 'Site' || editForm.controls['branches'].value.indexOf('RC') > -1">
                  <strong>{{ field.label }} <span *ngIf="isFieldRequired(field)" style="color: red">*</span></strong>
                  <ng-container [ngSwitch]="field.type">
                    <!-- LBU Field with Flags in Edit Mode -->
                    <div *ngSwitchCase="'ng-select'" class="d-flex align-items-center">
                      <ng-select
                        [class]="field.class"
                        [items]="field.items"
                        [multiple]="field.multiple"
                        bindLabel="name"
                        [formControlName]="field.formControlName"
                        [clearable]="!field.required">
                        <!-- Template for Dropdown Options -->
                        <ng-template *ngIf="field.label === 'LBU'" ng-option-tmp let-item="item">
                          <img [src]="item.flag" alt="{{ item.name }}" class="flag-icon me-1" /> {{ item.name }}
                        </ng-template>

                        <!-- Template for Selected Items -->
                        <ng-template *ngIf="field.label === 'LBU'" ng-label-tmp let-item="item">
                          <img [src]="item.flag" alt="{{ item.name }}" class="flag-icon me-1" /> {{ item.name }}
                        </ng-template>
                      </ng-select>
                    </div>
                    <app-user-autocomplete
                      *ngSwitchCase="'user-autocomplete'"
                      [placeholder]="'Select ' + field.label"
                      [multiple]="field.multiple"
                      [initialSelectedUsers]="field.value"
                      (onUserSelected)="onUsersSelected($event, field.formControlName)">
                    </app-user-autocomplete>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>

          <!-- Status Section -->
          <div class="col-md-3">
            <div class="row mb-2">
              <div class="col-12">
                <strong>Status</strong>
              </div>
            </div>
            <div *ngFor="let statusField of statusFields" class="status-col d-flex align-items-center mb-2">
              <strong class="me-2">{{ statusField.label }}:</strong>
              <ng-container *ngIf="isEditMode && isAdmin; else viewModeStatus">
                <input type="date" class="form-control flex-grow-1" [formControlName]="statusField.formControlName" />
              </ng-container>
              <ng-template #viewModeStatus>
                <div>{{ statusField.value | date : "dd/MM/yyyy" : "UTC" || "No Data" }}</div>
              </ng-template>
            </div>
          </div>
        </form>

        <!-- View Mode -->
        <ng-template #viewMode>
          <div class="row">
            <div class="col-md-9">
              <div class="row">
                <ng-container *ngFor="let field of viewFields">
                  <div class="col-md-3 mb-2" *ngIf="field.label != 'Site' || editForm.controls['branches'].value.indexOf('RC') > -1"
                  attr.data-cypress="{{field.label}}">
                    <strong>{{ field.label }}</strong>
                    <!-- LBU Field with Flags in View Mode -->
                    <div *ngIf="field.label === 'LBU' && field.value != [] && field.value != 'No Data'; else defaultView">
                      <ng-container *ngFor="let country of field.value">
                        <img [src]="country.flag" title="{{ country.name }}" alt="{{ country.name }}" class="flag-icon me-1" />
                      </ng-container>
                    </div>
                    <ng-template #defaultView>
                      <div>{{ field.value || "No Data" }}</div>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-md-3">
              <div class="row mb-2">
                <div class="col-12">
                  <strong>Status</strong>
                </div>
              </div>
              <ng-container *ngFor="let statusField of statusFields">
                <div class="status-col d-flex align-items-center" *ngIf="statusField.label !== 'Decommissioning' || statusField.value">
                  <strong class="me-2">{{ statusField.label }}:</strong>
                  <div *ngIf="statusField.value; else noData">
                    {{ statusField.value | date : "dd/MM/yyyy" : "UTC" }}
                  </div>
                  <ng-template #noData>
                    <div>No Data</div>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
