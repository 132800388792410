<popup-header [dialogRef]="dialogRef" icon="add_circle" text="Add New Product"></popup-header>
<div class="add-product-dialog">
  <form [formGroup]="addProductForm">
    <!-- Logo -->
    <div class="logo-container" (click)="triggerFileUpload()" (keydown.enter)="triggerFileUpload()" (keypress)="handleKeyPress($event)">
      <div class="logo-circle">
        <img *ngIf="addProductForm.get('logo')?.value" [src]="addProductForm.get('logo')?.value" alt="Uploaded Logo" />
        <span *ngIf="!addProductForm.get('logo')?.value" class="upload-text">+</span>
      </div>
      <input type="file" id="logo" #fileInput (change)="onLogoChange($event)" accept="image/*" hidden />
    </div>

    <!-- Product Name -->
    <div class="form-group">
      <label for="productName" class="form-label">Product Name <span style="color: red">*</span></label>
      <input type="text" class="form-control" id="productName" formControlName="name" required placeholder="Enter product name" />
    </div>

    <!-- Branch -->
    <div class="form-group">
      <label for="branches" class="form-label">Branch</label>
      <ng-select [items]="branches" formControlName="branches" [multiple]="true" bindLabel="name" placeholder="Select branches"> </ng-select>
    </div>

    <!-- LBU -->
    <div class="form-group">
      <label for="lbu" class="form-label">LBU</label>
      <ng-select [items]="lbu" formControlName="lbu" [multiple]="true" bindLabel="name" placeholder="Select lbu"> </ng-select>
    </div>

    <!-- Site -->
    <div class="form-group" *ngIf="showSiteField">
      <label for="site" class="form-label">Site</label>
      <ng-select [items]="sites" formControlName="site" [multiple]="true" bindLabel="name" placeholder="Select site"> </ng-select>
    </div>

    <!-- Domain -->
    <div class="form-group">
      <label for="domain" class="form-label">Domain <span style="color: red">*</span></label>
      <ng-select [items]="domains" formControlName="domain" [multiple]="false" placeholder="Select domain"> </ng-select>
    </div>

    <!-- Sponsor -->
    <div class="form-group">
      <label for="sponsors" class="form-label">Sponsor</label>
      <app-user-autocomplete [placeholder]="'Select Sponsors'" (onUserSelected)="onUsersSelected($event, 'sponsors')" [multiple]="true">
      </app-user-autocomplete>
    </div>

    <!-- Product Owner -->
    <div class="form-group">
      <label for="productOwner" class="form-label">Product Owner <span style="color: red">*</span></label>
      <app-user-autocomplete [placeholder]="'Select Product Owner'" (onUserSelected)="onUsersSelected($event, 'productOwners')" [multiple]="true">
      </app-user-autocomplete>
    </div>

    <!-- Domain / Asset Lead -->
    <div class="form-group">
      <label for="domainLead" class="form-label">Domain / Asset Lead</label>
      <app-user-autocomplete [placeholder]="'Select Domain / Asset Lead'" (onUserSelected)="onUsersSelected($event, 'domainLeads')" [multiple]="true">
      </app-user-autocomplete>
    </div>

    <!-- IT Scaler -->
    <div class="form-group">
      <label for="itScaler" class="form-label">IT Scaler <span style="color: red">*</span></label>
      <app-user-autocomplete [placeholder]="'Select IT Scaler'" (onUserSelected)="onUsersSelected($event, 'itScaler')" [multiple]="false">
      </app-user-autocomplete>
    </div>

    <!-- Change Coordinators -->
    <div class="form-group">
      <label for="changeCoordinators" class="form-label">Change Coordinators</label>
      <app-user-autocomplete
        [placeholder]="'Select Change Coordinators'"
        (onUserSelected)="onUsersSelected($event, 'changeCoordinators')"
        [multiple]="true">
      </app-user-autocomplete>
    </div>

    <!-- Squad Profile -->
    <div class="form-group">
      <label for="squadProfile" class="form-label">Squad Profile <span style="color: red">*</span></label>
      <ng-select [items]="squadProfiles" formControlName="squadProfile" [multiple]="false" placeholder="Select squad profile"> </ng-select>
    </div>

    <!-- Status -->
    <div class="form-group">
      <label for="statusDates" class="form-label">Status Dates</label>
      <div class="status-group">
        <div class="status-field">
          <label for="creationDate">Creation Date</label>
          <input type="date" class="form-control" formControlName="statusCreationDate" />
        </div>
        <div class="status-field">
          <label for="transferDate">Transfer Date</label>
          <input type="date" class="form-control" formControlName="statusTransferDate" />
        </div>
        <div class="status-field">
          <label for="runDate">Run Date</label>
          <input type="date" class="form-control" formControlName="statusRunDate" />
        </div>
      </div>
    </div>

    <!-- Jira Key -->
    <div class="form-group">
      <label for="jiraKey" class="form-label">Jira Key <span style="color: red">*</span></label>
      <input type="text" class="form-control" id="jiraKey" formControlName="jiraKey" required placeholder="Enter Jira key" />
    </div>

    <!-- CMDB Key -->
    <div class="form-group">
      <label for="cmdbKey" class="form-label">CMDB Key for FinOPS <span style="color: red">*</span></label>
      <input type="text" class="form-control" id="cmdbKey" formControlName="cmdbKey" required placeholder="Enter CMDB key for FinOPS" />
    </div>

    <!-- Submit Button -->
    <div class="form-actions">
      <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="addProductForm.invalid">Add Product</button>
    </div>
  </form>
</div>
