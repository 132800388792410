<div class="product-container no-export" id="visible-component">
  <!-- Accordions for product details and roadmap -->
  <div class="accordion-container">
    <!-- Display product accordion section -->
    <app-product-details
      [isAdmin]="isAdmin"
      [selectedProductId]="productId"
      [productName]="productName"
      [isWriter]="isWriter.includes('Product Details')"
      [section]="toIdFormat(productName)"></app-product-details>

    <!-- Display highlights accordion section -->
    <app-highlights
      [isAdmin]="isAdmin"
      [productId]="productId"
      [isWriter]="isWriter.includes('Highlights')"
      [section]="toIdFormat('Highlights')"></app-highlights>

    <!-- Display roadmap accordion section -->
    <app-roadmap
      [isAdmin]="isAdmin"
      [productId]="productId"
      [isWriter]="isWriter.includes('Roadmap')"
      [section]="toIdFormat('Roadmap')"
      [canvasId]="'0'"></app-roadmap>

    <!-- Display the 8 pillars accordion section -->
    <eight-pillars
      [isAdmin]="isAdmin"
      [selectedProductId]="productId"
      [selectedProductName]="productName"
      [isWriter]="isWriter.includes('8 Pillars')"
      [section]="toIdFormat('8 Pillars')"
      [viewMode]="'8 pillars'"></eight-pillars>

    <focus [isAdmin]="isAdmin" [selectedProductId]="productId" [isWriter]="isWriter.includes('Focus')" [section]="toIdFormat('Focus')"></focus>

    <app-squad
      [isAdmin]="isAdmin"
      [isWriter]="isWriter.includes('Squad')"
      [productId]="productId"
      [productName]="productName"
      [section]="toIdFormat('Squad')"
      [canvasId]="'0'"></app-squad>

    <finops
      [isAdmin]="isAdmin"
      [isWriter]="isWriter.includes('FinOps')"
      [productId]="productId"
      [productName]="productName"
      [section]="toIdFormat('Finops')"
      [canvasId]="'0'"></finops>

    <!-- Display usage accordion section -->
    <app-usage
      [isAdmin]="isAdmin"
      [isWriter]="isWriter.includes('Product Usage & Feedback from End-Users')"
      [productId]="productId"
      [productName]="productName"
      [section]="toIdFormat('Usage')"
      [viewMode]="'7d'"
      [canvasId]="'0'"></app-usage>

    <toolbox
      [isAdmin]="isAdmin"
      [selectedProductId]="productId"
      [isWriter]="isWriter.includes('Toolbox')"
      [section]="toIdFormat('Toolbox')"></toolbox>
  </div>
</div>

<div class="product-container" id="hidden-component" style="visibility: hidden; height: 0" *ngIf="showHiddenComponent">
  <!-- Accordions for product details and roadmap -->
  <div class="accordion-container">
    <!-- Display product accordion section -->
    <app-product-details
      [isAdmin]="isAdmin"
      [selectedProductId]="productId"
      [productName]="productName"
      [isWriter]="isWriter.includes('Product Details')"
      [section]="toIdFormat(productName)"></app-product-details>

    <!-- Display highlights accordion section -->
    <app-highlights
      [isAdmin]="isAdmin"
      [productId]="productId"
      [isWriter]="isWriter.includes('Highlights')"
      [section]="toIdFormat('Highlights')"></app-highlights>

    <!-- Display roadmap accordion section -->
    <app-roadmap
      [isAdmin]="isAdmin"
      [productId]="productId"
      [isWriter]="isWriter.includes('Roadmap')"
      [section]="toIdFormat('Roadmap')"
      [canvasId]="'1'"></app-roadmap>

    <!-- Display the 8 pillars accordion section -->
    <eight-pillars
      [isAdmin]="isAdmin"
      [selectedProductId]="productId"
      [selectedProductName]="productName"
      [isWriter]="isWriter.includes('8 Pillars')"
      [section]="toIdFormat('8 Pillars')"
      [viewMode]="'8 pillars'"></eight-pillars>

    <eight-pillars
      [isAdmin]="isAdmin"
      [selectedProductId]="productId"
      [selectedProductName]="productName"
      [isWriter]="isWriter.includes('8 Pillars')"
      [section]="toIdFormat('8 Pillars')"
      [viewMode]="'Action plan'"></eight-pillars>

    <app-squad
      [isAdmin]="isAdmin"
      [isWriter]="isWriter.includes('Squad')"
      [productId]="productId"
      [productName]="productName"
      [section]="toIdFormat('Squad')"
      [canvasId]="'1'"></app-squad>

    <finops
      [isAdmin]="isAdmin"
      [isWriter]="isWriter.includes('FinOps')"
      [productId]="productId"
      [productName]="productName"
      [section]="toIdFormat('Finops')"
      [canvasId]="'1'"></finops>

    <!-- Display usage accordion section -->
    <app-usage
      [isAdmin]="isAdmin"
      [isWriter]="isWriter.includes('Product Usage & Feedback from End-Users')"
      [productId]="productId"
      [productName]="productName"
      [section]="toIdFormat('Usage')"
      [viewMode]="'7d'"
      [canvasId]="'1'"></app-usage>

    <app-usage
      [isAdmin]="isAdmin"
      [isWriter]="isWriter.includes('Product Usage & Feedback from End-Users')"
      [productId]="productId"
      [productName]="productName"
      [section]="toIdFormat('Usage')"
      [viewMode]="'1month'"
      [canvasId]="'2'"></app-usage>
  </div>
</div>
