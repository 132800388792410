import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {SquadComment} from "../common/comment/comment.interface";

export interface SprintMetrics {
  sprintName: string;
  commitment: number;
  realizedUS: number;
  realizedBug: number;
  compliance: number;
  repartitionProduct1: number;
  repartitionProduct2: number;
  secondProductName: string;
  funcEvo: number;
  funcAdapt: number;
  deploy: number;
  cyber: number;
  tech: number;
  run: number;
}

@Injectable({
  providedIn: "root",
})
export class SquadService {
  private apiUrl: string = `${environment.webProtocol}://${environment.serverUrl}/api/squad`;

  constructor(private http: HttpClient) {}

  getLastSixSprintMetrics(productId: number): Observable<SprintMetrics[]> {
    return this.http.get<SprintMetrics[]>(`${this.apiUrl}/${productId}/last-six-sprints`);
  }

  saveStaffingComment = (productId: number, staffingComment: string): Observable<SquadComment> => {
    return this.http.post<SquadComment>(`${this.apiUrl}/staffing-comment`, {productId, staffingComment});
  };

  saveActivityComment = (productId: number, activityComment: string): Observable<SquadComment> => {
    return this.http.post<SquadComment>(`${this.apiUrl}/activity-comment`, {productId, activityComment});
  };

  getComment(productId: number): Observable<SquadComment> {
    return this.http.get<SquadComment>(`${this.apiUrl}/comments/${productId}`);
  }
}
