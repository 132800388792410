import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedStateService {
  private showHiddenComponentSubject = new BehaviorSubject<boolean>(false);
  private isLoadingSubject = new BehaviorSubject<{[key: string]: boolean}>({
    Roadmap: true,
    "Squad-0": true,
    "Squad-gauges-0": true,
    Finops: true,
    "Usage-7d": true,
    "Squad-1": true,
    "Squad-gauges-1": true,
    "Usage-1month": true,
  });
  showHiddenComponent$ = this.showHiddenComponentSubject.asObservable();
  isLoading$ = this.isLoadingSubject.asObservable();

  constructor() {}

  setShowHiddenComponent(show: boolean): void {
    this.showHiddenComponentSubject.next(show);
  }

  setLoadingStatus(section: string, isLoading: boolean): void {
    const currentState = this.isLoadingSubject.value;
    const newState = {...currentState, [section]: isLoading};
    this.isLoadingSubject.next(newState);
  }

  clearLoadingStatus(): void {
    this.isLoadingSubject.next({});
  }
}
