import {schema as baseSchema, marks} from "ngx-editor/schema";
import {addMentionNodes} from "prosemirror-mentions";
import {Schema} from "prosemirror-model";

// Add mention nodes to the schema
const schema = new Schema({
  nodes: addMentionNodes(baseSchema.spec.nodes),
  marks,
});

export default schema;
