<div class="accordion">
  <div class="accordion-item">
    <h4 class="accordion-header d-flex" [id]="'accordion-btn-header-' + section">
      <button
        class="accordion-button flex-grow-1"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#accordion-btn-collapse-' + section"
        aria-expanded="true"
        [attr.aria-controls]="'accordion-btn-collapse-' + section">
        <div class="container-left-accordion-title">
          <span class="material-icons-outlined color_red" aria-hidden="true" color="accent">
            {{ "graphic_eq" }}
          </span>
          {{ "8 Pillars" }}
        </div>
      </button>
      <div class="container-right">
        <span class="last-update-text" *ngIf="lastUpdatePillars && selectedSegment == 0"
          >Last Update : {{ lastUpdatePillars | date : "dd/MM/yyyy" : "UTC" }}</span
        >
        <span class="last-update-text" *ngIf="lastUpdateActionPlan && selectedSegment == 1"
          >Last Update : {{ lastUpdateActionPlan | date : "dd/MM/yyyy" : "UTC" }}</span
        >
        <div class="additional-btn no-export" *ngIf="selectedSegment == 1">
          <button type="button" class="btn btn-secondary" [matMenuTriggerFor]="menuExport">
            <span class="material-icons-outlined" aria-hidden="true">file_download</span>Export
          </button>
        </div>
        <mat-menu #menuExport="matMenu" yPosition="below">
          <button mat-menu-item (click)="exportInXlsx()">Export in .xlsx</button>
          <button mat-menu-item (click)="exportInJpeg()">Export in .jpeg</button>
          <button mat-menu-item (click)="exportInPdf()">Export in .pdf</button>
        </mat-menu>
        <div class="additional-btn no-export" *ngIf="isWriter || isAdmin">
          <button type="button" class="btn btn-secondary" (click)="openAddActionDialog()">
            <span class="material-icons-outlined" aria-hidden="true">add</span>Add Action
          </button>
        </div>
      </div>
    </h4>
    <div [id]="'accordion-btn-collapse-' + section" class="accordion-collapse show" [attr.aria-labelledby]="'accordion-btn-header-' + section">
      <div class="accordion-body">
        <div class="segmented-control-container">
          <button
            class="segmented-control-item"
            [ngClass]="{selected: selectedSegment == 0}"
            (click)="selectedSegment = 0"
            (keydown)="$event.key == 'Enter' ? (selectedSegment = 0) : null">
            8 pillars
          </button>
          <button
            class="segmented-control-item"
            [ngClass]="{selected: selectedSegment == 1}"
            (click)="selectedSegment = 1"
            (keydown)="$event.key == 'Enter' ? (selectedSegment = 1) : null">
            Action plan
          </button>
        </div>
        <div *ngIf="selectedSegment == 0" class="pillars-container">
          <div class="one-pillar" *ngFor="let pillar of pillars">
            <div class="pillar-header">
              <div class="dot-container">
                <span
                  *ngIf="!isWriter && !isAdmin"
                  class="dot"
                  [style.backgroundColor]="pillar.status"
                  [matTooltip]="getStatusDescriptionFromColor(pillar.status)"></span>
                <span
                  *ngIf="isWriter || isAdmin"
                  class="dot clickable"
                  [style.backgroundColor]="pillar.status"
                  [matMenuTriggerFor]="menuGeneralStatus"
                  [matTooltip]="getStatusDescriptionFromColor(pillar.status)"></span>
                <mat-menu #menuGeneralStatus="matMenu" class="menu-general-status" xPosition="after" yPosition="below">
                  <div
                    class="menu-dot-container"
                    [matTooltip]="getStatusDescriptionFromColor('green')"
                    matTooltipPosition="right"
                    matTooltipClass="dot-tooltip">
                    <button
                      class="dot clickable dot-green"
                      [ngClass]="{selected: pillar.status === 'green'}"
                      (click)="changePillarStatus(pillar, 'green')"
                      (keydown)="$event.key == 'Enter' ? changePillarStatus(pillar, 'green') : null"></button>
                  </div>
                  <div
                    class="menu-dot-container"
                    [matTooltip]="getStatusDescriptionFromColor('orange')"
                    matTooltipPosition="right"
                    matTooltipClass="dot-tooltip">
                    <button
                      class="dot clickable dot-orange"
                      [ngClass]="{selected: pillar.status === 'orange'}"
                      (click)="changePillarStatus(pillar, 'orange')"
                      (keydown)="$event.key == 'Enter' ? changePillarStatus(pillar, 'orange') : null"></button>
                  </div>
                  <div
                    class="menu-dot-container"
                    [matTooltip]="getStatusDescriptionFromColor('red')"
                    matTooltipPosition="right"
                    matTooltipClass="dot-tooltip">
                    <button
                      class="dot clickable dot-red"
                      [ngClass]="{selected: pillar.status === 'red'}"
                      (click)="changePillarStatus(pillar, 'red')"
                      (keydown)="$event.key == 'Enter' ? changePillarStatus(pillar, 'red') : null"></button>
                  </div>
                  <div
                    class="menu-dot-container"
                    [matTooltip]="getStatusDescriptionFromColor('grey')"
                    matTooltipPosition="right"
                    matTooltipClass="dot-tooltip">
                    <button
                      class="dot clickable dot-grey"
                      [ngClass]="{selected: pillar.status === 'grey'}"
                      (click)="changePillarStatus(pillar, 'grey')"
                      (keydown)="$event.key == 'Enter' ? changePillarStatus(pillar, 'grey') : null"></button>
                  </div>
                </mat-menu>
              </div>
              <div class="pillar-name">{{ pillar.name }}</div>
              <button *ngIf="isWriter || isAdmin" class="material-icons-outlined pillar-icon pillar-add-icon" (click)="addComment(pillar.id)">
                add
              </button>
            </div>
            <div class="pillar-comments">
              <div *ngIf="pillar.comments.length == 0" class="no-comment-div">No comment yet.</div>
              <div *ngFor="let comment of pillar.comments" class="pillar-comment-item">
                <div class="inline">
                  <span class="dot" [style.backgroundColor]="comment.status"></span>
                </div>
                <div class="inline comment-text">
                  {{ comment.comment }}
                </div>
                <span
                  *ngIf="isWriter || isAdmin"
                  class="material-icons-outlined pillar-icon pillar-more-icon"
                  aria-hidden="true"
                  [matMenuTriggerFor]="menuComment"
                  >more_vert</span
                >
                <mat-menu #menuComment="matMenu">
                  <button mat-menu-item class="menu-item" (click)="editComment(comment)">Edit comment</button>
                  <button mat-menu-item class="menu-item" (click)="deleteComment(comment.id)">Delete comment</button>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
        <!-- Display Action Plan if selectedSegment is 1 -->
        <div *ngIf="selectedSegment == 1">
          <app-action-plan
            [isWriter]="isWriter"
            (sendLastUpdate)="lastUpdateActionPlan = $event"
            [productId]="selectedProductId"
            [productName]="selectedProductName"
            [productOwners]="selectedProductOwners"></app-action-plan>
        </div>
      </div>
    </div>
  </div>
</div>
