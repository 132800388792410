import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {Chart} from "chart.js";
import {fontString} from "chart.js/helpers";
import moment from "moment";
import {ToastrService} from "ngx-toastr";
import {SharedStateService} from "../common/shared-state.service";
import {FinopsService} from "./finops.service";

@Component({
  selector: "finops",
  templateUrl: "./finops.component.html",
  styleUrls: ["./finops.component.scss"],
})
export class FinopsComponent implements OnChanges {
  @Input() productId: number;
  @Input() productName: string;
  @Input() section!: string;
  @Input() isWriter: boolean;
  @Input() isAdmin: boolean = false;
  @Input() canvasId: string;

  loading: boolean = true;
  chart: any;
  lastUpdate: Date;

  finOpsData: any;
  hasData: boolean;

  comment: string = "";

  barColors = ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0", "#546E7A", "#26a69a", "#D10CE8", "#b4e732", "#f5878b"];

  constructor(private finopsService: FinopsService, private toastrService: ToastrService, private sharedStateService: SharedStateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.productId?.currentValue) {
      this.loading = true;
      this.sharedStateService.setLoadingStatus("Finops", true);
      this.loadComment();
      this.finopsService.getFinOpsByProduct(this.productId).subscribe(
        (data) => {
          this.finOpsData = data;
          this.hasData = this.finOpsData.length > 0;
          this.createChart();
          this.loading = false;
          this.sharedStateService.setLoadingStatus("Finops", false);
        },
        () => {
          this.toastrService.error("An error occurred while fetching the FinOps data");
          this.hasData = false;
          this.loading = false;
          this.sharedStateService.setLoadingStatus("Finops", false);
        }
      );
    }
  }

  createChart(): void {
    if (this.chart) this.chart.destroy();

    let datasets = [];
    let allDates = [...new Set(this.finOpsData.map((row) => row.yearMonth))];
    let allServices = [...new Set(this.finOpsData.map((row) => row.service))].sort((a: string, b: string) => a.localeCompare(b)); // Alphabetical order to comply with the FinOps dashboard display
    let sumsForEachDates = [];

    for (let date of allDates) {
      sumsForEachDates.push(
        this.finOpsData
          .filter((row) => row.yearMonth == date)
          .map((row) => row.cost)
          .reduce((partialSum, a) => partialSum + a, 0)
      );
    }

    for (let i = 0; i < allServices.length; i++) {
      let dataForThisService: Array<any> = this.finOpsData.filter((row) => row.service == allServices[i]);

      let datasetData = [];

      for (let date of allDates) {
        if (dataForThisService.filter((row) => row.yearMonth == date).length > 0)
          datasetData.push(dataForThisService.filter((row) => row.yearMonth == date)[0].cost);
        else datasetData.push(0);
      }

      datasets.push({
        label: allServices[i],
        data: datasetData,
        backgroundColor: this.barColors[i],
        stack: "stack1",
        order: i,
        type: "bar",
        borderWidth: {
          top: 2,
          right: 0,
          bottom: 0,
          left: 0,
        },
        borderColor: "#FFF",
        borderRadius: 4,
      });
    }

    const ensureBarHeightSetup = {
      id: "ensureBarHeightSetup",
      afterRender(chart) {
        setTimeout(() => {
          if (!chart._updated) {
            chart._updated = true;
            chart.update(); // Force re-render after bars are set
          }
        }, 0);
      },
    };

    Chart.register(ensureBarHeightSetup);

    this.chart = new Chart(`finopsChart-${this.canvasId}`, {
      data: {
        labels: [...new Set(this.finOpsData.map((row) => row.yearMonth))].map((x: any) => moment(new Date(x)).locale("en").format("Q[Q]YY MMM")),
        datasets: datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              font: {
                weight: "bold",
              },
            },
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: (value) => {
                return this.formatEuroKilo(value) + " €";
              },
              font: {
                weight: "bold",
              },
            },
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            position: "top",
            onHover: this.handleHover,
            onLeave: this.handleLeave,
          },
          datalabels: {
            display: (context) => {
              const meta = context.chart.getDatasetMeta(context.datasetIndex);
              const bar: any = meta.data[context.dataIndex];

              return !isNaN(bar.height) && bar.height > 25;
            },
            formatter: (value) => {
              return this.formatEuroKilo(value) + " €";
            },
            color: "black",
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const datasetLabel = tooltipItem.dataset.label;
                const value = this.formatEuroKilo(parseFloat(tooltipItem.raw.toString()));

                return `${datasetLabel}: ${value} €`;
              },
            },
          },
        },
        animation: {
          onProgress: () => {
            let chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.font = fontString(Chart.defaults.font.size, Chart.defaults.font.style, Chart.defaults.font.family);
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";

            let meta = chartInstance.getDatasetMeta(datasets.length - 1);
            meta.data.forEach((bar, index) => {
              let data = this.formatEuroKilo(sumsForEachDates[index]) + " €";
              ctx.fillText(data, bar.x, bar.y - 5);
            });
          },
        },
      },
    });
  }

  handleHover(evt, item, legend) {
    legend.chart.data.datasets.forEach((dataset, datasetIndex) => {
      if (datasetIndex !== item.datasetIndex) {
        if (dataset.backgroundColor && dataset.backgroundColor.length === 7) {
          dataset.backgroundColor = dataset.backgroundColor + "4D";
        }
      }
    });

    legend.chart.update();
  }

  handleLeave(evt, item, legend) {
    legend.chart.data.datasets.forEach((dataset) => {
      if (dataset.backgroundColor && dataset.backgroundColor.length === 9) {
        dataset.backgroundColor = dataset.backgroundColor.slice(0, -2);
      }
    });

    legend.chart.update();
  }

  saveComment(comment: string) {
    this.comment = comment;
    if (this.comment) {
      if (this.comment == "<p></p>") this.comment = null; // Reset the comment

      this.finopsService.saveComment(this.productId, this.comment).subscribe(
        (response) => {
          this.toastrService.success("Comment saved successfully!");
        },
        () => {
          this.toastrService.error("Error while saving the comment.");
        }
      );
    }
  }

  loadComment() {
    this.finopsService.getComment(this.productId).subscribe(
      (res) => {
        this.comment = res?.comment ? res.comment : "";
        this.lastUpdate = res?.modificationDate;
      },
      () => {
        this.toastrService.error("Error while loading comments.");
      }
    );
  }

  formatEuroKilo(value): string {
    if (value < 1000) return value.toFixed(2).toString();
    else {
      return (value / 1000).toFixed(2) + "K";
    }
  }
}
