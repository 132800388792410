import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Editor, Toolbar} from "ngx-editor";
import {ToastrService} from "ngx-toastr";
import {UserDTO} from "src/app/common/user.service";
import {DateUtil} from "src/app/common/util/date-util";
import plugins from "src/app/common/util/plugins";
import schema from "src/app/common/util/schema";
import {EightPillarsService, Pillar} from "src/app/eight-pillars/eight-pillars.service";
import {ActionPlan, ActionPlanService} from "../action-plan.service";

@Component({
  selector: "app-manage-action",
  templateUrl: "./manage-action.component.html",
  styleUrls: ["./manage-action.component.scss"],
})
export class ManageActionComponent implements OnInit {
  actionForm: FormGroup;
  title: string;
  isEditMode: boolean;
  pillars: Pillar[] = [];
  statusOptions: string[] = ["To do", "In progress", "Blocked", "Done", "Non Applicable"];
  activeMentionIndex: number = 0;
  saveInProgress: boolean = false;

  public editor: Editor;
  public toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["ordered_list", "bullet_list"],
    [{heading: ["h1", "h2", "h3", "h4", "h5", "h6"]}],
    ["text_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
  ];

  constructor(
    readonly fb: FormBuilder,
    readonly actionPlanService: ActionPlanService,
    public dialogRef: MatDialogRef<ManageActionComponent>,
    readonly toastrService: ToastrService,
    readonly pillarService: EightPillarsService,
    @Inject(MAT_DIALOG_DATA) public data: {productId: number; action?: ActionPlan}
  ) {
    this.isEditMode = !!this.data.action;
    this.title = this.isEditMode ? "Edit Action" : "Create New Action";
  }

  updateStatusOptions(type: string) {
    const statusControl = this.actionForm.get("status");
    const deadlineControl = this.actionForm.get("deadline");
    if (type === "Info") {
      // Set status to 'Non Applicable' when type is 'Info'
      this.statusOptions = ["Non Applicable"];
      statusControl?.setValue("Non Applicable");
      statusControl?.disable(); // Disable the status field
    } else {
      // Enable status and update available options when type is not 'Info'
      statusControl?.enable();
      this.statusOptions = ["To do", "In progress", "Blocked", "Done"]; // Valid options
      if (statusControl?.value === "Non Applicable") {
        statusControl?.setValue("To do"); // Reset to a valid status if it's 'Non Applicable'
      }
    }

    if (type === "Action") {
      deadlineControl?.enable();
    } else {
      deadlineControl?.disable();
    }
  }

  ngOnInit(): void {
    this.editor = new Editor({
      schema,
      plugins,
    });
    const isInfoType = this.isEditMode && this.data.action?.type === "Info";
    const isActionType = this.isEditMode && this.data.action?.type === "Action";

    this.actionForm = this.fb.group({
      title: ["", Validators.required],
      type: ["Action", Validators.required],
      status: [{value: isInfoType ? "Non Applicable" : "To do", disabled: isInfoType}, Validators.required],
      pillarCard: [{id: null}, Validators.required],
      pillarName: ["", Validators.required],
      creationDate: [{value: new Date().toISOString().split("T")[0], disabled: this.isEditMode}, Validators.required],
      responsible: ["", Validators.required],
      deadline: [{value: "", disabled: isActionType}],
      comment: [""],
      committee: ["No committee"],
    });
    if (this.data.productId) {
      this.pillarService.getPillarsByProductId(this.data.productId).subscribe((data) => {
        this.pillars = data;
        if (this.pillars.length > 0) {
          this.actionForm.patchValue({
            pillarCard: {id: this.pillars[0].id},
            pillarName: this.pillars[0].name,
          });

          if (this.isEditMode && this.data.action) {
            let pillar = this.pillars.find((pillar) => pillar.name === this.data.action.pillarName);
            let pillarId = pillar.id;
            this.actionForm.patchValue({
              title: this.data.action.title,
              type: this.data.action.type,
              status: this.data.action.status,
              pillarCard: {id: pillarId},
              pillarName: this.data.action.pillarName,
              creationDate: DateUtil.dateWithoutTimezone(this.data.action.creationDate),
              responsible: this.data.action.responsible,
              deadline: this.data.action.deadline ? DateUtil.dateWithoutTimezone(this.data.action.deadline) : "",
              comment: this.data.action.comment,
              committee: this.data.action.committee,
            });
          }
        }
      });
    }
    this.actionForm.get("pillarName")?.valueChanges.subscribe((newPillarName: string) => {
      const selectedPillar = this.pillars.find((pillar) => pillar.name === newPillarName);
      if (selectedPillar) {
        this.actionForm.patchValue({pillarCard: {id: selectedPillar.id}}, {emitEvent: false});
      }
    });
    // Listen to changes in the 'type' field
    this.actionForm.get("type")?.valueChanges.subscribe((type: string) => {
      this.updateStatusOptions(type);
    });

    const initialType = this.actionForm.get("type")?.value;
    this.updateStatusOptions(initialType);
  }

  onSubmit(): void {
    if (this.actionForm.valid) {
      const rawFormData = this.actionForm.getRawValue();
      const formData = {
        ...rawFormData,
        pillarCard: {id: rawFormData.pillarCard.id},
      };
      this.saveInProgress = true;

      if (this.isEditMode) {
        this.actionPlanService.updateAction(this.data.action.id, formData).subscribe(
          () => {
            this.saveInProgress = false;
            this.toastrService.success("Action has been updated!");
            this.dialogRef.close(true);
          },
          () => {
            this.saveInProgress = false;
            this.toastrService.error("Failed to update action.");
          }
        );
      } else {
        this.actionPlanService.createAction(formData).subscribe(
          () => {
            this.saveInProgress = false;
            this.toastrService.success("Action has been created!");
            this.dialogRef.close(true);
          },
          () => {
            this.saveInProgress = false;
            this.toastrService.error("Failed to create action.");
          }
        );
      }
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onUsersSelected(selectedUsers: UserDTO[], key: string): void {
    this.actionForm.patchValue({
      [key]: selectedUsers[0],
    });
  }
}
